import React from "react";
import Grid from "@material-ui/core/Grid";
import Head from "./Head";
import AppBar from "./Appbar";
import Loading from "../components/Loading";
import Footer from "../components/Footer";
import firebase from "firebase/app";
import { config } from "../firebase/settings";
import { connect } from "react-redux";
import { authenticateUser } from "../state/actions/auth";
import { toggleModal } from "../state/actions/components";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../styles/theme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Contact from "./Contact";
import Modal from "../components/Modal";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";
import { navigate } from "gatsby";
import CookieConsent from "react-cookie-consent";
import "@material-ui/icons";

class Layout extends React.Component<Props, any> {
  componentDidMount() {
    const { isLoading, authenticateUser, authRequired } = this.props;

    if (authRequired) {
      const config = {
        isOpen: true,
        confirmAction: false,
        content: null,
        defaultState: false,
        isLoading: true,
        labelOne: null,
        labelTwo: null,
        onlyClose: false,
        showButtons: false,
        toggleModal: null,
        title: "Verifying Account ..."
      };
      this.props.toggleModal(config);
      firebase.auth().onAuthStateChanged(async _user => {
        const token = localStorage.getItem(process.env.TOKEN_ID);
        const productExp = new Date(parseInt(token) * 1000);
        const now = new Date();
        const isActive = now < productExp;

        if (isActive && _user) {
          this.props.toggleModal({ defaultState: true });
        } else if (_user && !isActive) {
          this.props.toggleModal({ defaultState: true });
          navigate("/account");
        } else {
          this.props.toggleModal({ defaultState: true });
          navigate("/signin");
        }
      });
    }
    // if (isLoading) {
    //   authenticateUser();
    // }
  }
  render() {
    const {
      components,
      user,
      isActive,
      isLoading,
      authRequired,
      copies,
      seo
    } = this.props;
    const copiesExist = copies !== undefined && copies !== null;

    const content = components.map((component, index) => (
      <Grid
        item
        xs={component.columns}
        key={index}
        className={component.className}
      >
        {component.content}
      </Grid>
    ));
    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className="layout">
            <Head seo={seo} />
            <AppBar user={user} copies={copiesExist ? copies : []} />
            <Grid container className="layout__content">
              {content}
            </Grid>
            <Footer />
            <Contact />
            <Modal />
            <CookieConsent
              buttonStyle={{ color: "#fff", fontSize: "13px", background: "#99476c" }}
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </div>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isActive: state.auth.isActive,
  isLoading: state.auth.isLoading,
  modal: state.components.modalState
});

export default connect(
  mapStateToProps,
  { authenticateUser, toggleModal }
)(Layout);

interface Props {
  components: Component[];
  user: any;
  isActive: boolean;
  isLoading: boolean;
  authenticateUser: () => void;
  authRequired: boolean;
  copies: any;
  seo: { searchIndex: string; metaDescription: string };
  toggleModal: any;
  modal: any;
}

interface Component {
  columns: "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  content: any;
  className: string;
}

import { createMuiTheme } from "@material-ui/core/styles";
const settings = {
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    },
    primary: {
      light: "rgba(93, 186, 196, 1)",
      main: "rgba(71, 144, 153, 1)",
      dark: "rgba(85, 123, 128, 1)",
      contrastText: "#fff"
    },
    secondary: {
      light: "rgba(179, 81, 124, 1)",
      main: "rgba(153, 71, 108, 1)",
      dark: "rgba(128, 85, 104, 1)",
      contrastText: "#fff"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  }
};

export default createMuiTheme(settings);
import React from "react";
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

export default class TabbedMenu extends React.Component<any, any> {

  state = {
    tabIndex: 0
  }

  changeTab = (tabIndex: number) => {
    this.setState({
      tabIndex
    })
  }

  render() {
    const tabIndex = this.state.tabIndex
    const labels = this.props.options.map((option: any, tabIndex: number) => (
      <Tab 
        label={option.label}
        onClick={()=> this.changeTab(tabIndex)}
        key={tabIndex}
      />
    ))

    const content = this.props.options.map((option: any, tabIndex: number) => (
      <div key={tabIndex}>
        {option.content}
      </div>
    ))

    return (
      <div>
        <Tabs 
          value={tabIndex} 
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="primary"
        >
          {labels}
        </Tabs>
        {content[tabIndex]}
      </div>
    );
  }
}

import React from "react";
import { connect } from "react-redux";
import {
  updateCardAttribute,
  toggleCombo,
  refreshCards,
  calculateCombos,
  setTemp
} from "../state/actions/cards";
import { toggleModal } from "../state/actions/components";
import ComboStats from "./ComboStats";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ComboItem from "./ComboItem";
import ComboPanel from "./ComboPanel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import snake from "to-snake-case";
const _ = require("lodash");
const uniqid = require("uniqid");

class Combos extends React.Component<Props, any> {
  state = {
    name: { id: "", name: "" },
    lockedName: "",
    nameLocked: false,
    nameError: false,
    errorMsg: "",
    error: false,
    newCombo: false,
    nameButtonText: "Name Combo",
    open: false,
    temp: [],
    initialList: [],
    prevState: []
  };

  updateName = (event: any) => {
    this.setState({
      name: { id: this.state.name.id, name: event.target.value }
    });
  };

  newCombo = () => {
    const cardList = this.props.cardList;
    this.setState(
      {
        name: { id: uniqid("combo_"), name: "" },
        lockedName: "",
        nameLocked: false,
        nameError: false,
        errorMsg: "",
        nameButtonText: "Name Combo",
        newCombo: true,
        temp: this.props.cardList
      },
      () => {
        this.props.setTemp(cardList);
        this.handleOpen();
      }
    );
  };

  toggleTemp = (params: any) => {
    const group = params.group;
    const name = params.name;
    const cardList = this.props.temp.map(item => item);
    const output = { ...params.card };
    const comboExists = output.temp.hasOwnProperty(name);

    let prevState = this.state.prevState;

    if (!comboExists) {
      output.temp[`${name}`] = [];
      output.temp[`${name}`].push(`${group}`);
    }

    if (comboExists) {
      const combo = output.temp[name];
      const groupExists = combo.indexOf(group) !== -1;
      if (groupExists) {
        const hasPrevCards = prevState.length > 0;
        if (!hasPrevCards) {
          prevState.push({
            card: output,
            group,
            comboId: name,
            state: true
          });
        }
        if (hasPrevCards) {
          prevState = prevState.map(item => {
            const idMatch = item.card.id === output.id;
            if (!idMatch) {
              return item;
            }

            if (idMatch) {
              return {
                card: output,
                group,
                comboId: name,
                state: false
              };
            }
          });
        }

        output.temp[name] = output.temp[name].filter(item => item !== group);
      }

      if (!groupExists) {
        prevState.push({
          card: output,
          group,
          comboId: name,
          state: false
        });
        output.temp[name].push(group);
      }
    }

    const temp = cardList.map(item => {
      const match = item.id === output.id;
      if (match) return output;
      return item;
    });
    this.props.setTemp(temp);
    this.setState({ temp, prevState }, () => {});
  };

  isTemp = (params: any) => {
    const card = { ...params.card };
    const name = params.name;
    const comboExists = card.temp.hasOwnProperty(name);
    if (comboExists) {
      const combo = card.temp[name];
      const group = `group_${params.group}`;
      const comboGroupMatch = combo.includes(group);

      if (comboGroupMatch) {
        return true;
      }
      return false;
    }

    return false;
  };

  saveCombo = () => {
    const comboGroupCheck = args => {
      const { position, card } = args;
      const checkAll = position.toLowerCase() === "all";
      const combos = card.temp;
      let result;

      for (const combo in combos) {
        if (combos.hasOwnProperty(combo)) {
          const comboGroups = combos[combo];
          if (!checkAll) {
            const hasRequiredPosition =
              comboGroups.filter(item => item === position).length > 0;
            if (hasRequiredPosition) {
              result = true;
            }
          }
          if (checkAll) {
            const hasRequiredPosition =
              comboGroups.filter(
                item =>
                  item === "group_1" ||
                  item === "group_2" ||
                  item === "group_3" ||
                  item === "group_4" ||
                  item === "group_5"
              ).length > 0;
            if (hasRequiredPosition) {
              result = true;
            }
          }
        }
      }

      return result;
    };
    const comboName = this.state.name.name;
    const comboId = this.state.name.id;
    const tempCards = this.props.temp.map(item => item);
    const realCards = this.props.cardList.map(item => item);

    const hasName = comboName.length > 0;
    let error = false;
    let errorMsg = "";

    if (!hasName) {
      error = true;
      errorMsg = "Please, name your combo";
      this.setState({ error, errorMsg });
    }

    if (hasName) {
      const cardsWithCombos = tempCards.filter(card => {
        const hasCombos = Object.keys(card.temp).length > 0;
        if (!hasCombos) {
          return false;
        }
        if (hasCombos) {
          return true;
        }
      });
      const hasCombos = cardsWithCombos.length > 0;

      if (!hasCombos) {
        error = true;
        errorMsg = "The combo must have at least one input";
        this.setState({ error, errorMsg });
      }

      if (hasCombos) {
        const cardsWithMinimumReqs = cardsWithCombos.filter(card =>
          comboGroupCheck({ card, position: "all" })
        );
        const hasMinReqs = cardsWithMinimumReqs.length > 0;

        if (!hasMinReqs) {
          error = true;
          errorMsg = "The combo must have at least one input";
          this.setState({ error, errorMsg });
        }

        if (hasMinReqs) {
          const cardsWithOutputs = cardsWithCombos.filter(card =>
            comboGroupCheck({ card, position: "group_6" })
          );
          const combosHaveOutputs = cardsWithOutputs.length > 0;
          if (!combosHaveOutputs) {
            error = true;
            errorMsg = "The combo must have at least one output";
            this.setState({ error, errorMsg });
          }

          if (combosHaveOutputs) {
            const output = realCards.map(card => {
              tempCards.forEach(tempCard => {
                const idMatch = tempCard.id === card.id;
                if (idMatch) {
                  const realCombos = card.combo;
                  const tempCombos = tempCard.temp;
                  const comboKeys = Object.keys(tempCombos);
                  card.index.push({ comboName, comboId });
                  comboKeys.forEach(key => {
                    realCombos[key] = tempCombos[key];
                  });
                  card.temp = {};
                }
              });
              return card;
            });

            this.setState(
              { name: { name: "", id: "" }, error, errorMsg },
              () => {
                this.props.refreshCards(output);
                this.calculateProbs();
                this.handleClose(true);
              }
            );
          }
        }
      }
    }
  };

  editCombo = (params: any) => {
    const { comboName, comboId } = params.combo;
    const cardList = this.props.cardList.map(card => card);
    const _temp = cardList.map(card => {
      const output = { ...card };
      const hasCombo = card.combo[comboId] !== undefined;
      if (hasCombo) {
        output.temp = output.combo;
        return output;
      }
      return card;
    });
    const temp = _temp.filter(card => card !== null);
    this.setState(
      {
        name: { id: comboId, name: comboName },
        temp
      },
      () => {
        this.props.setTemp(_temp);
        this.handleOpen();
      }
    );
  };

  removeCombo = (params: any) => {
    const { comboId } = params.combo;
    const output = this.props.cardList.map(card => {
      const hasCombo = card.combo[comboId] !== undefined;
      if (hasCombo) {
        delete card.combo[comboId];
        card.index = card.index.filter(item => item.comboId !== comboId);
      }
      return card;
    });

    this.props.refreshCards(output);
    this.calculateProbs();
  };

  calculateProbs = () => {
    this.props.calculateCombos({
      cardList: this.props.cardList,
      deckSize: this.props.deckSize,
      handFirst: this.props.handFirst
    });
    this.props.toggleModal(false);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (didSave: boolean) => {
    if (!didSave) {
      const _output = this.props.cardList.map(card => card);
      const prevState = this.state.prevState.map(item => item);
      const output = _output.map(card => {
        prevState.forEach(item => {
          const cardMatch = item.card.id === card.id;
          const { group, comboId, state } = item;
          const comboExists = card.combo.hasOwnProperty(comboId);

          if (comboExists) {
            if (cardMatch && !state) {
              card.combo[comboId] = card.combo[comboId].filter(
                g => g !== group
              );
            }
            if (cardMatch && state) {
              card.combo[comboId].push(group);
            }
          }
        });
        card.temp = {};
        return card;
      });
      this.props.setTemp(output);
      this.setState({ open: false, prevState: [] });
    }

    if (didSave) {
      this.setState({ open: false, prevState: [] }, () => {});
    }
  };

  snakeCase = (string: any) => {
    return snake(string);
  };

  generateComboArray = cards => {
    const combos = cards.map((card: any) => {
      const hasKeys = Object.keys(card.combo).length > 0;
      if (hasKeys) {
        const comboList = Object.keys(card.combo).map(key => {
          let output = null;
          card.index.forEach(item => {
            const idMatch = key === item.comboId;
            if (idMatch) {
              output = item;
            }
          });
          return output;
        });
        return comboList.filter(item => item !== null);
      }
      return null;
    });
    const filteredCombos = combos.filter((item: any) => item !== null);
    const raw = [].concat.apply([], Array.from(new Set(filteredCombos)));
    const _output = Array.from(new Set(raw));
    const uniqueOutput = _.uniqBy(_output, (item: any) => item.comboId);

    return uniqueOutput;
  };

  render() {
    const cardList = this.props.cardList;
    const combos = this.generateComboArray(cardList);

    const comboList = this.props.comboList;
    const params = {
      editCombo: this.editCombo,
      removeCombo: this.removeCombo,
      cardList: this.props.temp,
      state: this.state
    };
    const hasCards = cardList.length > 0;
    const panels = [];
    for (let i = 0; i < 7; i++) {
      const index = i + 1;
      const name = this.state.name;
      const snakeCase = this.snakeCase;
      const isChecked = this.isTemp;
      const toggleCombo = this.toggleTemp;
      let output = false;
      let misc = false;

      if (i === 5) {
        output = true;
        misc = false;
      }

      if (i === 6) {
        output = false;
        misc = true;
      }

      const panel = (
        <ComboPanel
          params={{
            index,
            name,
            output,
            misc,
            snakeCase,
            isChecked,
            toggleCombo,
            temp: this.props.temp
          }}
        />
      );

      panels.push(panel);
    }

    const { calculatingProbs, probError, probMsg, copy } = this.props;

    return (
      <div className="combo-tab">
        <div className="combo-tab__combos">
          {ComboStats({ comboList, calculatingProbs, probError, probMsg })}
          {combos.map((combo: any, index: number) =>
            ComboItem({
              params: {
                index,
                combo,
                comboList,
                editCombo: this.editCombo,
                errorMsg: params.state.errorMsg,
                removeCombo: this.removeCombo,
                cardList: params.cardList
              }
            })
          )}
        </div>
        {hasCards ? (
          <div className="field-group combo-tab__buttons">
            <Button
              className="input-button combo-tab__button"
              variant="contained"
              color="default"
              type="raised"
              onClick={() => this.newCombo()}
            >
              New Combo
            </Button>
          </div>
        ) : null}
        <div className="combo-tab__copy">{copy}</div>
        <Dialog
          open={this.state.open}
          onClose={() => this.handleClose(false)}
        >
          <DialogTitle id="form-dialog-title">
            Create / Edit Combo
          </DialogTitle>
          <DialogContent>
            <div>
              <Typography>
                {this.state.errorMsg === "" ? "" : this.state.errorMsg}
              </Typography>
              <div>
                <FormControl>
                  <TextField
                    id="comboName"
                    label="Combo Name"
                    defaultValue={this.state.name.name}
                    onChange={event => this.updateName(event)}
                  />
                </FormControl>
              </div>
              <div>{panels.map(panel => panel)}</div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveCombo} color="primary">
              Save Combo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  cardList: state.cards.cardList,
  modal: state.components.modal,
  comboList: state.cards.combos,
  deckSize: state.cards.deckSize,
  temp: state.cards.temp,
  combos: state.cards.combos,
  calculatingProbs: state.cards.calculatingProbs,
  probError: state.cards.probError,
  probMsg: state.cards.probMsg,
  handFirst: state.cards.handFirst
});

export default connect(
  mapStateToProps,
  {
    updateCardAttribute,
    toggleModal,
    toggleCombo,
    refreshCards,
    calculateCombos,
    setTemp
  }
)(Combos);

interface Props {
  cardList: any;
  modal: any;
  comboList: any;
  toggleCombo: any;
  updateCardAttribute: any;
  toggleModal: any;
  handFirst: any;
  deckSize: any;
  calculateCombos: any;
  refreshCards: any;
  setTemp: any;
  temp: any;
  combos: any;
  calculatingProbs: boolean;
  probError: boolean;
  probMsg: string;
  copy: any;
}

// interface Combos {
//   cardList: any[]
//   modal: boolean
//   comboList: any
//   toggleModal: any
//   updateCardAttribute: any
//   removeCombo: any
// }

// interface State {
//   name: string
//   lockedName: string
//   nameLocked: boolean
//   nameError: boolean
//   errorMsg: string
//   nameButtonText: string
//   newCombo: boolean
// }

import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { toggleCard } from "../state/actions/cards";
import Typography from "@material-ui/core/Typography";
import { Card } from "../interfaces/cards";
import Divider from "@material-ui/core/Divider";
import Loading from "../components/Loading";
import SearchItem from "./SearchItem";

export const formatPendulumText = (card: Card) => {
  const text = card.text;
  const marker = text.indexOf("\n-") - 1;
  const marker2 = text.indexOf("\n[") - 1;
  const pendulumText = text.substr(0, marker);
  const cardText = text.substr(marker2);

  return (
    <div>
      <Typography>{pendulumText}</Typography>
      <Typography>{cardText}</Typography>
    </div>
  );
};

class SearchResults extends React.Component<any, any> {
  getIndex = (array: any[], card: any) => {
    const id = card.id;
    const match = array.filter(item => item.id === id);
    const hasMatch = match.length > 0;

    if (!hasMatch) {
      return false;
    }

    if (hasMatch) {
      return true;
    }
  };

  render() {
    const isLoading = this.props.loading;
    const aToZ = (a, b) => {
      if (a.name > b.name) return 1;
      return -1;
    };
    const results: Card[] = this.props.searchResults;
    const monsterCards = results
      .filter(
        card =>
          card.extraDeck !== true && card.category1.toLowerCase() === "monster"
      )
      .sort(aToZ);
    const spellCards = results
      .filter(
        card =>
          card.extraDeck !== true && card.category1.toLowerCase() === "spell"
      )
      .sort(aToZ);
    const trapCards = results
      .filter(
        card =>
          card.extraDeck !== true && card.category1.toLowerCase() === "trap"
      )
      .sort(aToZ);
    const extraDeck = results.filter(card => card.extraDeck === true);
    const hasMonsters = monsterCards.length > 0;
    const hasSpells = spellCards.length > 0;
    const hasTraps = trapCards.length > 0;
    const hasExtra = extraDeck.length > 0;
    

    if (isLoading) {
      return (
        <div className="loading__container">
          <Loading title="Searching ..." />
        </div>
      );
    } else {
      return (
        <div className="search-results">
          <List>
            {hasMonsters ? (
              <div>
                <ListItem>
                  <ListItemText>Monsters</ListItemText>
                </ListItem>
                <Divider />
                {monsterCards.map(card => (
                  <SearchItem
                    cardList={this.props.cardList}
                    card={card}
                    formatPendulumText={formatPendulumText}
                    getIndex={this.getIndex}
                    toggleCard={this.props.toggleCard}
                  />
                ))}
              </div>
            ) : null}
            {hasSpells ? (
              <div>
                <ListItem>
                  <ListItemText>Spells</ListItemText>
                </ListItem>
                <Divider />
                {spellCards.map(card => (
                  <SearchItem
                    cardList={this.props.cardList}
                    card={card}
                    formatPendulumText={formatPendulumText}
                    getIndex={this.getIndex}
                    toggleCard={this.props.toggleCard}
                  />
                ))}
              </div>
            ) : null}
            {hasTraps ? (
              <div>
                <ListItem>
                  <ListItemText>Traps</ListItemText>
                </ListItem>
                <Divider />
                {trapCards.map(card => (
                  <SearchItem
                    cardList={this.props.cardList}
                    card={card}
                    formatPendulumText={formatPendulumText}
                    getIndex={this.getIndex}
                    toggleCard={this.props.toggleCard}
                  />
                ))}
              </div>
            ) : null}
            {hasExtra ? (
              <div>
                <ListItem>
                  <ListItemText>Extra Deck</ListItemText>
                </ListItem>
                <Divider />
                {extraDeck.map(card => (
                  <SearchItem
                    cardList={this.props.cardList}
                    card={card}
                    formatPendulumText={formatPendulumText}
                    getIndex={this.getIndex}
                    toggleCard={this.props.toggleCard}
                  />
                ))}
              </div>
            ) : null}
          </List>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  // searchResults: state.cards.queryResults,
  cardList: state.cards.cardList
});

// interface Props {
//   cardList: any
//   searchResults: Cards
//   toggleCard: any
// }

export default connect(
  mapStateToProps,
  { toggleCard }
)(SearchResults);

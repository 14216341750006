import React from "react";
import { Helmet } from "react-helmet";
import "../styles/index.scss";
// import 'typeface-roboto';

const Head = (props: any) => {
  const hasSEO = props.seo !== null && props.seo !== undefined;
  let metaDescription;
  let searchIndex;
  let hasDescription;
  const styles = `
  .mdl-textfield__input {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.12);
    display: block;
    font-size: 16px;
    font-family: "Helvetica","Arial",sans-serif;
    margin: 0;
    padding: 4px 0;
    width: 100%;
    background: 0 0;
    text-align: left;
    color: inherit
}

.mdl-textfield {
    position: relative;
    font-size: 16px;
    display: inline-block;
    box-sizing: border-box;
    width: 300px;
    max-width: 100%;
    margin: 0;
    padding: 20px 0
}

audio,canvas,iframe,img,svg,video {
    vertical-align: middle
}

::selection {
    background: #b3d4fc;
    text-shadow: none
}
  `;

  if (hasSEO) {
    metaDescription = props.seo.metaDescription;
    searchIndex = props.seo.searchIndex;
    hasDescription =
      metaDescription !== undefined && metaDescription !== null;
  }

  return (
    <Helmet>
      <style>
        {styles}
      </style>
      <meta
        name="description"
        content={hasDescription ? metaDescription.internal.content : ""}
      />
      <meta name="robots" content={hasSEO ? (searchIndex) : ""} />
      <title>DeckDB | The Most Advanced Yugioh Deck Deck Builder</title>
    </Helmet>
  );
};

export default Head;

import React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ToolTipText from "./ToolTipText";

const SearchItem = (props: any) => {
  const { cardList, card, formatPendulumText, getIndex, toggleCard } = props;
  return (
    <Tooltip
      classes={{ tooltip: "tooltip", popper: "tooltip__popper" }}
      className="tooltip"
      title={
        <ToolTipText card={card} formatPendulumText={formatPendulumText} />
      }
    >
      <ListItem key={card.id} dense button>
        <ListItemText
          disableTypography
          primary={<Typography>{card.name}</Typography>}
        />
        <ListItemSecondaryAction>
          <Checkbox
            onChange={() =>
              toggleCard({
                cards: cardList,
                card
              })
            }
            checked={getIndex(cardList, card)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>
  );
};

export default SearchItem;
import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { styles } from "../styles/inline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import CheckBoxType from "./CheckBoxType";
import { classifyCardCategories } from "../functions/general";
import { connect } from "react-redux";

const ComboPanel = (props: Props) => {
  const { params, temp } = props;
  const {
    hasMonsters,
    monsters,
    hasSpells,
    spells,
    hasTraps,
    traps,
    hasExtra,
    extra
  } = classifyCardCategories(temp);
  // console.log({ component: 'ComboPanel', temp, monsters, spells, traps, extra });
  const panelTitle = (params: any) => {
    if (params.output !== true && params.misc !== true) {
      return <Typography>Piece {params.index}</Typography>;
    } else if (params.output !== true) {
      return <Typography>Misc. Requirements</Typography>;
    } else {
      return <Typography>Combo Output</Typography>;
    }
  };

  const generateList = (cards: any, name: string) => (
    <div className="combo-panel__group" key={name}>
      <Typography variant="h6" className="combo-panel__title">
        {name}
      </Typography>
      <List className="combo-panel__list">
        {cards.map((card: any) => (
          <ListItem key={card.id} dense button>
            <ListItemText primary={card.name} />
            <ListItemSecondaryAction>
              <CheckBoxType
                params={params}
                toggleCombo={params.toggleCombo}
                snakeCase={params.snakeCase}
                isChecked={params.isChecked}
                card={card}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ExpansionPanel
      style={styles.expansion}
      key={params.index}
      className="combo-panel"
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {panelTitle(params)}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="combo-panel__card-list">
        {hasMonsters ? generateList(monsters, "Monsters") : null}
        {hasSpells ? generateList(spells, "Spells") : null}
        {hasTraps ? generateList(traps, "Traps") : null}
        {hasExtra ? generateList(extra, "Extra Deck") : null}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

interface Props {
  params: any;
  temp: any;
}

const mapStateToProps = (state: any) => ({
  temp: state.cards.temp
});

export default connect(
  mapStateToProps,
  null
)(ComboPanel);

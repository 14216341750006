import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import { getCombo } from "../functions/general";
import Chip from "@material-ui/core/Chip";
import { Typography } from "@material-ui/core";
// import './styles.scss'

const ComboItem = (props: any) => {
  const params = props.params;
  const { index, combo, comboList, editCombo, removeCombo } = params;
  const name = combo.comboName;
  const id = combo.comboId;
  const probs = getCombo({ combo, comboList });
  const max = probs.max.toFixed(2);

  return (
    <ExpansionPanel key={index} className="combo-panel">
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className="combo-panel__stats">
          <Typography variant="body2">{name}</Typography>
          <Chip label={`Maximum: ${max}`} />
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className="combo-panel__button-group">
          <Button
            className="input-button"
            variant="contained"
            color="default"
            type="raised"
            onClick={() =>
              editCombo({
                combo
              })
            }
          >
            Edit Combo
          </Button>
          <Button
            className="input-button"
            variant="contained"
            color="default"
            type="raised"
            onClick={() => removeCombo({ combo })}
          >
            Remove Combo
          </Button>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ComboItem;

import React from "react";
import Drawer from '@material-ui/core/Drawer'
import { connect } from 'react-redux'
import { toggleSidebar } from '../state/actions/components'
import TabbedMenu from './TabbedMenu'
import CardSearch from './CardSearch'
import Optimizer from './Optimizer'
import Combos from './Combos'
import SaveLoad from './Saveload'
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

interface Options {
  label: any
  content: any
}

class SidebarComponent extends React.Component<any, any> {
  render() {
    const { copies } = this.props;
    const { cardSearchCopy, combosCopy, loadSaveCopy, optimizerCopy } = copies;

    const options: Options[] = [
      {
        label: "Card Search",
        content: <CardSearch copy={cardSearchCopy} />
      },
      {
        label: "Combos",
        content: <Combos copy={combosCopy} />
      },
      {
        label: "Optimizer",
        content: <Optimizer copy={optimizerCopy} />
      },
      {
        label: "Save/Load",
        content: <SaveLoad copy={loadSaveCopy} />
      }
    ];
    return (
      <div>
        <SwipeableDrawer
          classes={{ 
            paper: 'sidebar',
            modal: 'sidebar__modal',
            paperAnchorLeft: 'sidebar__left',
            docked: 'sidebar__docked'
          }}
          open={this.props.sidebar}
          onClose={() => this.props.toggleSidebar(false)}
          onOpen={() => this.props.toggleSidebar(true)}
        >
          <TabbedMenu options={options} />
        </SwipeableDrawer>
      </div>
    );
  }
}

// SidebarComponent.propTypes = {
//   sidebar: PropTypes.bool.isRequired,
//   toggleSidebar: PropTypes.func.isRequired,
// }

const mapStateToProps = (state: any) => ({
  sidebar: state.components.sidebar,
  isActive: state.auth.isActive
})

export default connect(mapStateToProps, { toggleSidebar })(SidebarComponent)
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

const CheckBoxType = (props: Props) => {
  const { params, toggleCombo, snakeCase, isChecked, card } = props;
  if (params.output !== true || params.misc !== true) {
    return (
      <Checkbox
        onChange={() => toggleCombo({ card, group: `group_${params.index}`, name: params.name.id })}
        checked={isChecked({ card, name: params.name.id, group: params.index })}
      />
    )
  } else if (params.output !== true) {
    return (
      <Checkbox
        onChange={() => toggleCombo({ card, group: `group_${params.index}_misc`, name: params.name.id })}
        checked={isChecked({ card, name: params.name.id, group: params.index })}
      />
    )
  } else {
    return (
      <Checkbox
        onChange={() => toggleCombo({ card, group: `group_${params.index}_output`, name: params.name.id })}
        checked={isChecked({ card, name: params.name.id, group: params.index })}
      />
    )
  }
}

export default CheckBoxType;

interface Props {
  toggleCombo: any
  snakeCase: any
  isChecked: any
  card: any
  params: any
}
export const styles: any = {
  marginBottom: "16px",
  marginLeft: "8px",
  marginRight: "8px",
  header: {
    textAlign: "center"
  },
  sidebarOptions: {
    display: "flex",
    flexDirection: "column"
  },
  centerText: {
    display: "inline-block",
    textAlign: "center"
  },
  expansion: {
    marginBottom: "16px"
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap"
  },
  flexItem: {
    flex: "1"
  },
  effectMonster: {
    backgroundColor: "#CBA47D",
    color: "white"
  },
  spellCard: {
    backgroundColor: "#BADDD7"
    // color: 'white',
  },
  trapCard: {
    backgroundColor: "#E6BCD2"
    // color: 'white',
  },
  normalMonster: {
    backgroundColor: "#ECDEB6"
  },
  ritualCard: {
    backgroundColor: "#C2C6D2"
    // color: 'white'
  },
  fusionCard: {
    backgroundColor: "#DAC3DF"
    // color: 'white'
  },
  synchroCard: {
    backgroundColor: "#FDFDFD"
  },
  xyzCard: {
    backgroundColor: "#000",
    color: "gold"
  }
};

export const heroStyles = (bgUrl: string) => {
  return {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgUrl}")`,
    height: "50%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundPosition: "relative"
  };
};

export const options: any = {
  category: [
    'All Cards', 
    'Monster', 
    'Spell', 
    'Trap'
  ],
    subCategoryM: [
      'All Categories',
      'Effect',
      'Flip',
      'Fusion',
      'Gemini',
      'Link',
      'Normal',
      'Pendulum',
      'Ritual',
      'Spirit',
      'Synchro',
      'Toon',
      'Tuner',
      'Union',
      'Xyz',
    ],
    subCategoryS: [
      'All Categories',
      'Continuous',
      'Equip',
      'Field',
      'Normal',
      'Quick-Play',
      'Ritual',
    ],
    subCategoryT: [
      'All Categories', 
      'Continuous', 
      'Counter',
      'Normal', 
    ],
    monsterAttribute: [
      'All Attributes',
      'Dark',
      'Divine',
      'Earth',
      'Fire',
      'Light',
      'Water',
      'Wind',
    ],
    monsterType: [
      'All Types',
      'Aqua',
      'Beast-Warrior',
      'Beast',
      'Creator-God',
      'Cyberse',
      'Dinosaur',
      'Divine-Beast',
      'Dragon',
      'Fairy',
      'Fiend',
      'Fish',
      'Insect',
      'Machine',
      'Plant',
      'Psychic',
      'Pyro',
      'Reptile',
      'Rock',
      'Sea-Serpent',
      'Spellcaster',
      'Thunder',
      'Warrior',
      'Winged Beast',
      'Wyrm',
      'Zombie',
    ],
    monsterLevel: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    monsterScale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
}
import {
  ADJUST_DECK,
  CALCULATE_COMBOS,
  LOAD_CONFIG,
  LOAD_DECKS,
  REMOVE_CARD,
  REMOVE_COMBO,
  RESET_VALUES,
  SEARCH_CARDS,
  TOGGLE_ATTACK,
  TOGGLE_CARD,
  TOGGLE_COMBO,
  TOGGLE_DEFENSE,
  TOGGLE_SPEED,
  UPDATE_CARD_ATTR,
  UPDATE_CARDLIST,
  UPDATE_LEVEL,
  UPDATE_MESSAGE,
  UPDATE_SCALE,
  UPDATE_TICK,
  UPDATE_VALUE,
  REFRESH_CARDS,
  SET_TEMP,
  CLEAR_ALL,
  SET_OP_STATE,
  SET_PROB_STATE,
  TOGGLE_SEARCH
} from "../actiontypes/cards";
import { saveAs } from "file-saver";

import firebase from "firebase/app";
import _ from "lodash";
import { config } from "../../firebase/settings";
import { cardDataCheck } from "../../functions/general";
firebase.initializeApp(config);

// Utility
const removeItem = (array: any, index: number) => {
  let newArray = array.slice();
  newArray.splice(index, 1);
  return newArray;
};

const insertItem = (array: any, card: any) => {
  const index = array.findIndex((item: any) => item.id === card.id);

  if (index === -1) {
    return [...array, card];
  } else {
    return removeItem(array, index);
  }
};

// export const saveDeck = (params: any) => {
//   const saveConfig = firebase.functions().httpsCallable('saveConfig');
//   // const { uid, deckId, name, cardList, combos, optimalResults } = params;
//   saveConfig(params).then((res) => {

//   })
// }

export const loadDecks = (params: any) => (dispatch: any) => {
  const firestore = firebase.firestore();
  const settings = {
    /* your settings... */
    timestampsInSnapshots: true
  };

  firestore.settings(settings);
  firebase
    .firestore()
    .collection(process.env.USERS)
    .doc(params.uid)
    .get()
    .then(doc => {
      const data: any = doc.data();
      const decks = data.decks;

      decks.forEach((deck: any) => {
        deck.name = deck.name.trim();
      });

      dispatch({
        type: LOAD_DECKS,
        payload: decks
      });
    })
    .catch(error => console.log(error));
};

export const loadConfig = (params: any) => (dispatch: any) => {
  const { cardList, combos, optimalResults } = params;
  const updatedCardList = cardList.map(card => {
    const hasNewProperties = card.hasOwnProperty("isRemoval");
    if (!hasNewProperties) {
      card = cardDataCheck(card);
    }
    card.temp = {};
    return card;
  });
  dispatch({
    type: LOAD_CONFIG,
    cardList: updatedCardList,
    combos,
    optimalResults
    // cardList: params.cardList,
    // combos: params.combos,
    // optimalResults: params.optimalResults
  });
};

export const removeCombo = (array: any, key: any) => (dispatch: any) => {
  const sanitizedArray = array.map((item: any) => {
    if (item.combo[key]) {
      delete item.combo[key];
      return item;
    } else {
      return item;
    }
  });

  dispatch({
    type: REMOVE_COMBO,
    payload: sanitizedArray
  });
};

export const removeCard = (cardList: any, card: any) => (dispatch: any) => {
  const cards = cardList;
  const payload = cards.filter(item => item.name !== card.name);

  dispatch({
    type: REMOVE_CARD,
    payload
  });
};

const updateItem = (array: any, card: any) => {
  const index = array.findIndex((item: any) => item.id === card.id);

  let newArray = array.slice();
  newArray.splice(index, 1);

  return [...newArray, card];
};

export const adjustDeck = (event: any, value: any) => (dispatch: any) => {
  dispatch({
    type: ADJUST_DECK,
    payload: value
  });
};

export const uploadCards = (file: any) => {
  const functions = firebase.functions();
  const upload = functions.httpsCallable("uploadCards");

  const createChunks = (array: any, perChunk: any) => {
    return array.reduce((result: any, item: any, index: any) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(item);

      return result;
    }, []);
  };

  const chunks = createChunks(file, 4000);



  chunks.forEach((chunk: any) => {
    const cards = { cards: chunk };

    upload(cards);
  });


};

export const updateCardAttribute = (params: any) => (dispatch: any) => {
  const card: any = params.card;
  const value = params.value;
  const cards = params.cards;
  const attribute = params.attribute;
  const index = cards.findIndex((item: any) => item.id === card.id);

  card[attribute] = value;

  cards[index] = card;

  dispatch({
    type: UPDATE_CARD_ATTR,
    payload: cards
  });
};

export const updateValue = (event: any, key: any) => (dispatch: any) => {
  const dispatchType = params => {
    const key = params.key;
    const payload = params.value;
    dispatch({
      type: RESET_VALUES
    });
    dispatch({
      type: UPDATE_VALUE,
      value: { key, payload }
    });
  };
  const value = event.target.value;
  const isNormal = value === "Normal";
  const isEffect = value === "Effect";
  const isFusion = value === "Fusion";
  const isRitual = value === "Ritual";
  const isSynchro = value === "Synchro";
  const isXyz = value === "Xyz";
  const isPendulum = value === "Pendulum";
  const isLink = value === "Link";
  const isTuner = value === "Tuner";
  const isGemini = value === "Gemini";
  const isUnion = value === "Union";
  const isSpirit = value === "Spirit";
  const isFlip = value === "Flip";
  const isToon = value === "Toon";
  const isContinuous = value === "Continuous";
  const isEquip = value === "Equip";
  const isField = value === "Field";
  const isCounter = value === "Counter";
  const isAllCategories = value === "All Categories";
  const isCategory = key === "selectedCategory";

  if (isAllCategories) {
    dispatch({
      type: RESET_VALUES
    });
  }

  if (isCategory) {
    dispatch({
      type: RESET_VALUES
    });
  }

  if (isNormal) {
    const key = "isNormal";
    const value = true;
    dispatchType({ key, value });
  }

  if (isEffect) {
    const key = "isEffect";
    const value = true;
    dispatchType({ key, value });
  }

  if (isEffect) {
    const key = "isEffect";
    const value = true;
    dispatchType({ key, value });
  }

  if (isFusion) {
    const key = "isFusion";
    const value = true;
    dispatchType({ key, value });
  }

  if (isRitual) {
    const key = "isRitual";
    const value = true;
    dispatchType({ key, value });
  }

  if (isSynchro) {
    const key = "isSynchro";
    const value = true;
    dispatchType({ key, value });
  }

  if (isXyz) {
    const key = "isXyz";
    const value = true;
    dispatchType({ key, value });
  }

  if (isPendulum) {
    const key = "isPendulum";
    const value = true;
    dispatchType({ key, value });
  }

  if (isLink) {
    const key = "isLink";
    const value = true;
    dispatchType({ key, value });
  }

  if (isTuner) {
    const key = "isTuner";
    const value = true;
    dispatchType({ key, value });
  }

  if (isGemini) {
    const key = "isGemini";
    const value = true;
    dispatchType({ key, value });
  }

  if (isUnion) {
    const key = "isUnion";
    const value = true;
    dispatchType({ key, value });
  }

  if (isSpirit) {
    const key = "isSpirit";
    const value = true;
    dispatchType({ key, value });
  }

  if (isFlip) {
    const key = "isFlip";
    const value = true;
    dispatchType({ key, value });
  }

  if (isToon) {
    const key = "isToon";
    const value = true;
    dispatchType({ key, value });
  }

  if (isContinuous) {
    const key = "isContinuous";
    const value = true;
    dispatchType({ key, value });
  }

  if (isEquip) {
    const key = "isEquip";
    const value = true;
    dispatchType({ key, value });
  }

  if (isField) {
    const key = "isField";
    const value = true;
    dispatchType({ key, value });
  }

  if (isCounter) {
    const key = "isCounter";
    const value = true;
    dispatchType({ key, value });
  }

  dispatch({
    type: UPDATE_VALUE,
    value: {
      key,
      payload: event.target.value
    }
  });
};

export const updateTickBox = (event: any, key: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_TICK,
    value: {
      key,
      payload: event.target.checked
    }
  });
};

export const updateLevel = (event: any, value: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_LEVEL,
    selectedLevel: value
  });
};

export const updateScale = (event: any, value: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_SCALE,
    selectedScale: value
  });
};

export const toggleAttack = (event: any, value: any) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_ATTACK,
    payload: value
  });
};

export const toggleDefense = (event: any, value: any) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_DEFENSE,
    payload: value
  });
};

export const toggleSpeed = (event: any, value: any) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_SPEED,
    payload: value
  });
};

/*
Params:
{
  category,
  monsterCategory,
  spellCategory,
  trapCategory,
  attribute,
  type,
  level/rank
  scale,
  name
}
*/

export const searchCards = (params: any) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_SEARCH,
    payload: true
  });
  const functions = firebase.functions();
  const search = functions.httpsCallable(process.env.SEARCH_CARDS);
  search(params)
    .then(response => {
      let results = response.data.hits;
      // This is so jank. Adding combo attribute after each query.
      // But it works tho...
      results = results.map((card: any) => {
        card.minimum = 0;
        card.actual = 0;
        card.priority = 0;
        card.combo = {};
        card.temp = {};
        card.index = [];
        card.isStarter = false;
        card.isRecovery = false;
        card.isRemoval = false;
        return card;
      });

      results = sorter(results);
      results = results.map(card => {
        card = cardDataCheck(card);
        return card;
      });

      dispatch({
        type: SEARCH_CARDS,
        payload: results
      });
      dispatch({
        type: TOGGLE_SEARCH,
        payload: false
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_MESSAGE,
        payload: error
      });
    });
};

export const calculateCombos = (args: any) => async (dispatch: any) => {
  const functions = firebase.functions();
  const calculateProbs = functions.httpsCallable(process.env.CALCULATE_PROBS);
  const { cardList, deckSize, handFirst } = args;
  let hand = 5;
  if (!handFirst) hand = 6;
  const params = { cardList, deckSize, hand };

  dispatch({
    type: SET_PROB_STATE,
    calculatingProbs: true,
    probError: false,
    probMsg: "Calculating Probabilities ...",
    combos: {}
  });

  try {
    const _probData = await calculateProbs(params);
    const probData = _probData.data;
    const { combos, cardList } = probData;
    let cards = sorter(cardList);
    cards = cards.map(card => {
      const cardCombos = card.combo;
      const hasCombos = Object.keys(cardCombos).length > 0;

      if (hasCombos) {
        for (const id in cardCombos) {
          if (cardCombos.hasOwnProperty(id)) {
            const combo = cardCombos[id];
            const hasGroup = combo.length > 0;
            if (!hasGroup) delete cardCombos[id];
          }
        }
      }
      return card;
    })

    dispatch({
      type: UPDATE_CARDLIST,
      payload: cards
    });

    dispatch({
      type: SET_PROB_STATE,
      calculatingProbs: false,
      probError: false,
      probMsg: "Calculating Probabilities ...",
      combos
    });
  } catch (error) {
    dispatch({
      type: SET_PROB_STATE,
      calculatingProbs: false,
      probError: true,
      probMsg: "An error has occured. Try again.",
      combos: {}
    });
  }
  // const params: any = {
  //   cardList: args.cardList,
  //   deckSize: args.deckSize,
  //   hand: 5
  // }

  // if (args.handFirst === false) {
  //   params.hand = 6
  // }

  // calculateProbs(params)
  //   .then(response => {
  //     const combos = response.data.combos
  //     let cardList = response.data.cardList

  //     cardList = sorter(cardList);

  //     dispatch({
  //       type: CALCULATE_COMBOS,
  //       payload: combos
  //     })

  //     dispatch({
  //       type: UPDATE_CARDLIST,
  //       payload: cardList
  //     })

  //   })
  //   .catch(error => console.log(error))
};

// const updatePriority = (args: any) => (dispatch: any) => {
//   const combos = args.combos
//   let cardList = args.cardList

//   dispatch({
//     type: ADJUST_DECK,
//     payload: cardList
//   })
// }

export const toggleCard = (params: any) => (dispatch: any) => {
  const cardList = sorter(params.cards);

  const card: any = params.card;

  dispatch({
    type: TOGGLE_CARD,
    payload: insertItem(cardList, card)
  });
};

export const toggleCombo = (params: any) => (dispatch: any) => {
  const cardArray = params.cards;
  const card: any = params.card;

  dispatch({
    type: TOGGLE_COMBO,
    payload: updateItem(cardArray, card)
  });
};

export const runOptimizer = (args: any) => async (dispatch: any) => {
  const functions = firebase.functions();
  const runOptimizer = functions.httpsCallable(process.env.RUN_OPTIMIZER);
  const calculateProbs = functions.httpsCallable(process.env.CALCULATE_PROBS);
  const { cardList, deck, attack, defense, speed } = args;
  const params: any = {
    cardList,
    deck,
    attack,
    defense,
    speed,
    hand: args.hand
  };
  let hand = args.hand ? 5 : 6;
  let optimizing = true;
  let optimizerError = false;
  let optimizerMsg = "Creating Optimal Deck Solution...";

  dispatch({
    type: SET_OP_STATE,
    optimizing,
    optimizerError,
    optimizerMsg,
    optimalResults: []
  });

  try {
    const _probData = await calculateProbs({
      cardList,
      deckSize: params.deck,
      hand
    });
    const probData = _probData.data;
    const cards = sorter(probData.cardList);
    const _opData = await runOptimizer({ cardList: cards, deck, attack, defense, speed, hand });
    
    const opData = _opData.data;
    const combos = probData.combos;
    
    const results = opData.results;
    const clean: any[] = [];

    Object.keys(results).forEach((key: any) => {
      if (results.feasible === true) {
        if (
          results[key] !== "bounded" ||
          results[key] !== "feasible" ||
          results[key] !== "result"
        ) {
          clean.push({
            name: key,
            count: results[key]
          });
          cards.forEach((card: any) => {
            if (results[card.name]) {
              card.actual = results[card.name];
            } else {
              card.actual = 0;
            }
          });
        }
      }
    });

    optimizing = false;

    dispatch({
      type: CALCULATE_COMBOS,
      payload: combos
    });

    dispatch({
      type: UPDATE_CARDLIST,
      payload: cards
    });

    dispatch({
      type: SET_OP_STATE,
      optimizing,
      optimizerError,
      optimalResults: clean
    });
  } catch (error) {
    optimizing = false;
    optimizerError = true;
    optimizerMsg = "An Error Occured. Try Again.";

    dispatch({
      type: SET_OP_STATE,
      optimizing,
      optimizerError,
      optimalResults: []
    });

    console.log(error);
  }
};

export const downloadDeck = (params: any) => {
  const { cards, results, deckName } = params;
  let generateBlob = (input: any) =>
    new Blob([input], {
      type: "text/plaincharset=utf-8"
    });

  const fileText = (params: any) => {
    const main = (cards: any) =>
      cards.map((card: any) => {
        if (card.extraDeck !== true) {
          return `${card.id} \n`;
        } else {
          return null;
        }
      });

    const extra = (cards: any) =>
      cards.map((card: any) => {
        if (card.extraDeck === true) {
          return `${card.id} \n`;
        } else {
          return null;
        }
      });

    const createDeck = (params: any) => {
      let deck: any[] = [];
      params.cards.forEach((card: any) => {
        params.results.forEach((result: any) => {
          if (card.name === result.name) {
            for (let i: number = 0; i < result.count; i++) {
              deck.push(card);
            }
          }
        });
      });
      return deck;
    };

    return `
# Created with DeckDB.com
# main
${main(createDeck(params)).join("\n")}
# extra
${extra(createDeck(params)).join("\n")}
  `;
  };

  const output = generateBlob(fileText(params));

  saveAs(output, `${deckName}.ydk`);
};

export const refreshCards = (cards: any) => (dispatch: any) => {
  dispatch({
    type: REFRESH_CARDS,
    payload: cards
  });
};

const sorter = (array: any[]) => {
  const isExtra: any = (card: any) => (card.extraDeck === false ? -1 : 1);
  const isFusion: any = (card: any) => (card.category2 === "fusion" ? -1 : 1);
  const isSynchro: any = (card: any) => (card.category2 === "synchro" ? -1 : 1);
  const isXyz: any = (card: any) => (card.category2 === "xyz" ? -1 : 1);
  const isMonster: any = (card: any) => (card.category1 === "monster" ? -1 : 1);
  const isNormalMonster: any = (card: any) =>
    card.category1 === "monster" && card.category2 === "normal" ? -1 : 1;
  const isRitualMonster: any = (card: any) =>
    card.category2 === "ritual" && card.category1 === "monster" ? -1 : 1;
  const isGeminiMonster: any = (card: any) =>
    card.category1 === "monster" && card.category2 === "" ? -1 : 1;
  const isToonMonster: any = (card: any) =>
    card.category2 === "toon" ||
    (card.category1 === "" &&
      card.category2 === "" &&
      card.effect === false &&
      card.type !== "")
      ? -1
      : 1;
  const isSpell: any = (card: any) => (card.category1 === "spell" ? -1 : 1);
  const isTrap: any = (card: any) => (card.category1 === "trap" ? -1 : 1);

  return _.sortBy(array, [
    isExtra,
    isFusion,
    isSynchro,
    isXyz,
    isMonster,
    isNormalMonster,
    isRitualMonster,
    isGeminiMonster,
    isToonMonster,
    isSpell,
    isTrap
  ]);
};

export const clearSearchResults = () => (dispatch: any) => {
  dispatch({
    type: SEARCH_CARDS,
    payload: []
  });
};

export const clearAll = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_ALL,
    payload: null
  });
};

export const setTemp = temp => (dispatch: any) => {
  dispatch({
    type: SET_TEMP,
    temp
  });
};

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const Loading = (props: any) => {
  const title = props.title;

  return (
    <div className="loading">
      <Typography variant="h5" className="loading__title">
        {title}
      </Typography>
      <CircularProgress className="loading__wheel" color="secondary" />
    </div>
  );
};

export default Loading;

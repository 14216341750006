import { AUTHENTICATE_USER } from "../actiontypes/auth";
import firebase from "firebase/app";

export const authenticateUser = () => (dispatch: any) => {
  firebase.auth().onAuthStateChanged(async (_user) => {
    const token = localStorage.getItem(process.env.TOKEN_ID);
    const productExp = new Date(parseInt(token) * 1000);
    const now = new Date();
    const isActive = now < productExp;

    if (_user && !isActive) {
      const email = _user.email;
      const firestore = firebase.firestore();
      const userDB = firestore.collection(process.env.USERS);
      const today = new Date();
      let user = null;
      const results = await userDB.where("email", "==", email).get();
      results.forEach(doc => (user = doc.data()));
      const hasUser = user !== null;

      if (hasUser) {
        localStorage.removeItem("emailForSignIn");
        const productExp = new Date(user.productExp * 1000);
        const isActive = today < productExp;
        if (isActive) {
          dispatch({
            type: AUTHENTICATE_USER,
            _user,
            user,
            isActive: true
          });
          localStorage.setItem(process.env.TOKEN_ID, `${user.productExp}`);
        }
      }
    }

    if (_user && isActive) {
      dispatch({
        type: AUTHENTICATE_USER,
        _user,
        user: null,
        isActive: true
      });
    }
    
    

    // if (_user) {
    //   let user = null;
    //   const today = new Date();
    //   const email = _user.email;
    //   const isVerified = _user.emailVerified;
    //   if (isVerified) {
    //     user = await getUser(email, user, today, dispatch, _user);
    //   }

    //   if (!isVerified) {
    //     dispatch({
    //       type: AUTHENTICATE_USER,
    //       _user,
    //       user: null,
    //       isActive: false
    //     });
    //   }
    // } else {
    //   dispatch({
    //     type: AUTHENTICATE_USER,
    //     _user: null,
    //     user: null,
    //     isActive: false
    //   });
    // }
  });
};
// async function getUser(email: string, user: any, today: Date, dispatch: any, _user: firebase.User) {
//   const firestore = firebase.firestore();
//   const userDB = firestore.collection(process.env.USERS);
//   const results = await userDB.where("email", "==", email).get();
//   results.forEach(doc => (user = doc.data()));
//   const hasUser = user !== null;
//   if (hasUser) {
//     const productExp = new Date(user.productExp * 1000);
//     const isActive = today < productExp;
//     if (isActive) {
//       localStorage.setItem(process.env.TOKEN_ID, '1');
//       dispatch({
//         type: AUTHENTICATE_USER,
//         _user,
//         user,
//         isActive: true
//       });
//     }
//     if (!isActive) {
//       localStorage.removeItem(process.env.TOKEN_ID);
//       const verifyStripeCustomer = firebase
//         .functions()
//         .httpsCallable("verifyStripeCustomer");
//       const _userActive = await verifyStripeCustomer({ user });
//       const response = _userActive.data;
//       const userActive = response.isActive;
//       if (!userActive) {
//         localStorage.removeItem(process.env.TOKEN_ID);
//         dispatch({
//           type: AUTHENTICATE_USER,
//           _user,
//           user,
//           isActive: false
//         });
//       }
//       if (userActive) {
//         localStorage.setItem(process.env.TOKEN_ID, "1");
//         dispatch({
//           type: AUTHENTICATE_USER,
//           _user,
//           user,
//           isActive: true
//         });
//       }
//     }
//   }
//   else {
//     location.reload();
//   }
//   return user;
// }


import React from 'react'
import ConditionalSelect from './ConditionalSelect'
import ConditionalSlider from './ConditionalSlider'

const SearchFields = (props: Props) => {
  const { 
    condition, levelDisabled, monsterAttribute,
    monsterType, scaleDisabled, selectedAttribute,
    selectedCategoryM, selectedCategoryS, selectedCategoryT,
    selectedLevel, selectedScale, selectedType, subCategoryM,
    subCategoryS, subCategoryT, updateLevel, updateScale, updateTickBox,
    updateValue
   } = props
  if (condition === 'All Categories') {
    return null
  } else if (condition === 'All Cards') {
    return null
  } else if (condition === 'Monster') {
    return (
      <div className="field-group">
        {/* Monster Category */}
        <ConditionalSelect
          condition={condition}
          selectedCategory="selectedCategoryM"
          subCategory={subCategoryM}
          updateValue={updateValue}
          value={selectedCategoryM}
        />
        {/* Monster Attribute */}
        <ConditionalSelect
          condition={'Attribute'}
          selectedCategory="selectedAttribute"
          subCategory={monsterAttribute}
          updateValue={updateValue}
          value={selectedAttribute}
        />
        {/* Monster Type */}
        <ConditionalSelect
          condition={'Type'}
          selectedCategory="selectedType"
          subCategory={monsterType}
          updateValue={updateValue}
          value={selectedType}
        />
        {/* Monster Level */}
        <ConditionalSlider
          condition="Level"
          selectedCategory="selectedLevel"
          min={0}
          max={12}
          selectedLevel={selectedLevel}
          selectedScale={selectedScale}
          levelDisabled={levelDisabled}
          scaleDisabled={scaleDisabled}
          updateScale={updateScale}
          updateLevel={updateLevel}
          updateTickBox={updateTickBox}
        />
        {/* Monster Scale */}
        <ConditionalSlider
          condition="Scale"
          selectedCategory="selectedScale"
          min={0}
          max={13}
          selectedLevel={selectedLevel}
          selectedScale={selectedScale}
          levelDisabled={levelDisabled}
          scaleDisabled={scaleDisabled}
          updateScale={updateScale}
          updateLevel={updateLevel}
          updateTickBox={updateTickBox}
        />
      </div>
    )
  } else if (condition === 'Spell') {
    return < ConditionalSelect
      condition={condition}
      selectedCategory="selectedCategoryS"
      subCategory={subCategoryS}
      updateValue={updateValue}
      value={selectedCategoryS}
    />
  } else if (condition === 'Trap') {
    return < ConditionalSelect
      condition={condition}
      selectedCategory="selectedCategoryT"
      subCategory={subCategoryT}
      updateValue={updateValue}
      value={selectedCategoryT}
    />
  }
  return null
}

export default SearchFields;

interface Props {
  condition: string | null
  levelDisabled: any
  monsterAttribute: any
  monsterType: any
  scaleDisabled: any
  selectedAttribute: any
  selectedCategoryM: any
  selectedCategoryS: any
  selectedCategoryT: any
  selectedLevel: any
  selectedScale: any
  selectedType: any
  subCategoryM: any
  subCategoryS: any
  subCategoryT: any
  updateLevel: any
  updateScale: any
  updateTickBox: any
  updateValue: any
}
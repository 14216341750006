import React from "react";
import { options } from "./form-options/options";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styles } from "../styles/inline";
import { connect } from "react-redux";

import {
  updateValue,
  updateTickBox,
  updateLevel,
  updateScale,
  searchCards,
  clearSearchResults
} from "../state/actions/cards";
import SearchResults from "./SearchResults";
import SearchFields from "./SearchFields";
import "firebase/functions";

class CardSearch extends React.Component<any, any> {
  state = {
    // Toggleable options (Does not change.)
    category: options.category,
    subCategoryM: options.subCategoryM,
    subCategoryS: options.subCategoryS,
    subCategoryT: options.subCategoryT,
    monsterAttribute: options.monsterAttribute,
    monsterType: options.monsterType,
    monsterLevel: options.monsterLevel,
    monsterScale: options.monsterScale,
    isSearching: false,
    hasError: false,
    searchResults: []
  };

  search = () => {
    const params = {
      name: this.props.cardName,
      category: this.props.selectedCategory,
      monsterCat: this.props.selectedCategoryM,
      isNormal: this.props.isNormal,
      isEffect: this.props.isEffect,
      isFusion: this.props.isFusion,
      isRitual: this.props.isRitual,
      isSynchro: this.props.isSynchro,
      isXyz: this.props.isXyz,
      isPendulum: this.props.isPendulum,
      isLink: this.props.isLink,
      isSpecialSum: this.props.isSpecialSum,
      isTuner: this.props.isTuner,
      isGemini: this.props.isGemini,
      isUnion: this.props.isUnion,
      isSpirit: this.props.isSpirit,
      isFlip: this.props.isFlip,
      isToon: this.props.isToon,
      isNomi: this.props.isNomi,
      isContinuous: this.props.isContinuous,
      isQuick: this.props.isQuick,
      isEquip: this.props.isEquip,
      isField: this.props.isField,
      isCounter: this.props.isCounter,
      spellCat: this.props.selectedCategoryS,
      trapCat: this.props.selectedCategoryT,
      attribute: this.props.selectedAttribute,
      type: this.props.selectedType,
      level: this.props.selectedLevel,
      scale: this.props.selectedScale,
      levelDisabled: this.props.levelDisabled,
      scaleDisabled: this.props.scaleDisabled
    };
    this.props.searchCards(params);
  };

  keyPressSearch = event => {
    const keyCode = event.keyCode;
    const isReturnKey = keyCode === 13;
    if (isReturnKey) {
      this.search();
    }
  };

  render() {
    const searchResults = this.props.queryResults;
    const hasResults = searchResults.length > 0;
    const copy = this.props.copy;
    const isSearching = this.props.isSearching;
    return (
      <div className="card-search field-group">
        <FormControl className="card-search__group">
          <InputLabel htmlFor="category">Category</InputLabel>
          <Select
            id="category"
            value={this.props.selectedCategory}
            onChange={event =>
              this.props.updateValue(event, "selectedCategory")
            }
          >
            {this.state.category.map((cat: any, index: number) => (
              <MenuItem value={cat} key={index}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SearchFields
          condition={this.props.selectedCategory}
          levelDisabled={this.props.levelDisabled}
          monsterAttribute={this.state.monsterAttribute}
          monsterType={this.state.monsterType}
          scaleDisabled={this.props.scaleDisabled}
          selectedAttribute={this.props.selectedAttribute}
          selectedCategoryM={this.props.selectedCategoryM}
          selectedCategoryS={this.props.selectedCategoryS}
          selectedCategoryT={this.props.selectedCategoryT}
          selectedLevel={this.props.selectedLevel}
          selectedScale={this.props.selectedScale}
          selectedType={this.props.selectedType}
          subCategoryM={this.state.subCategoryM}
          subCategoryS={this.state.subCategoryS}
          subCategoryT={this.state.subCategoryT}
          updateLevel={this.props.updateLevel}
          updateScale={this.props.updateScale}
          updateTickBox={this.props.updateTickBox}
          updateValue={this.props.updateValue}
        />
        <FormControl className="card-search__group">
          <TextField
            id="cardName"
            label="Card Name"
            defaultValue={this.props.cardName}
            onChange={event => this.props.updateValue(event, "cardName")}
            onKeyUp={event => this.keyPressSearch(event)}
          />
        </FormControl>
        <Button
          style={styles}
          className="input-button card-search__button"
          variant="contained"
          color="default"
          type="raised"
          onClick={() => this.search()}
        >
          Search
        </Button>
        {(hasResults || isSearching) ? (
          <SearchResults
            searchResults={searchResults}
            loading={isSearching}
          />
        ) : (
          <div className="card-search__copy">{copy}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  selectedCategory: state.cards.selectedCategory,
  selectedCategoryM: state.cards.selectedCategoryM,
  selectedCategoryS: state.cards.selectedCategoryS,
  selectedCategoryT: state.cards.selectedCategoryT,
  selectedAttribute: state.cards.selectedAttribute,
  selectedType: state.cards.selectedType,
  selectedLevel: state.cards.selectedLevel,
  selectedScale: state.cards.selectedScale,
  cardName: state.cards.cardName,
  queryResults: state.cards.queryResults,
  levelDisabled: state.cards.levelDisabled,
  scaleDisabled: state.cards.scaleDisabled,
  isNormal: state.cards.isNormal,
  isEffect: state.cards.isEffect,
  isFusion: state.cards.isFusion,
  isRitual: state.cards.isRitual,
  isSynchro: state.cards.isSynchro,
  isXyz: state.cards.isXyz,
  isPendulum: state.cards.isPendulum,
  isLink: state.cards.isLink,
  isSpecialSum: state.cards.isSpecialSum,
  isTuner: state.cards.isTuner,
  isGemini: state.cards.isGemini,
  isUnion: state.cards.isUnion,
  isSpirit: state.cards.isSpirit,
  isFlip: state.cards.isFlip,
  isToon: state.cards.isToon,
  isNomi: state.cards.isNomi,
  isContinuous: state.cards.isContinuous,
  isQuick: state.cards.isQuick,
  isEquip: state.cards.isEquip,
  isField: state.cards.isField,
  isCounter: state.cards.isCounter,
  isSearching: state.cards.isSearching,
});

export default connect(
  mapStateToProps,
  {
    updateValue,
    updateTickBox,
    updateLevel,
    updateScale,
    searchCards,
    clearSearchResults
  }
)(CardSearch);

interface CardSearch {
  updateValue: any;
  updateTickBox: any;
  updateLevel: any;
  updatescale: any;
  searchCards: any;
  queryResults: any[];
  cardName: string;
  category: string;
  selectedCategory: string;
  selectedCategoryM: string;
  selectedCategoryS: string;
  selectedCategoryT: string;
  selectedAttribute: string;
  selectedType: string;
  selectedLevel: number;
  selectedScale: number;
  levelDisabled: boolean;
  scaleDisabled: boolean;
  updateScale: any;
  isSearching: boolean;
}

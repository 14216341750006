import React from 'react';
import { navigate } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { toggleContact } from '../state/actions/components';
import Button from '@material-ui/core/Button';

const footer = (props: any) => {
  const navTo = (uri: string) => navigate(uri);
  return (
    <footer className="layout__footer">
      <div className="footer__copyright">
        <Typography variant="title">
          © {new Date().getFullYear()} DeckDB{" "}
        </Typography>
      </div>
      <div className="footer__nav">
        <Button
          className="footer__link"
          color="inherit"
          onClick={() => navTo("/privacy-policy")}
        >
          Privacy Policy
        </Button>
        <Button
          className="footer__link"
          color="inherit"
          onClick={() => navTo("/terms-of-service")}
        >
          Terms of Service
        </Button>
        <Button
          className="footer__link"
          color="inherit"
          onClick={() => props.toggleContact(true)}
        >
          Contact
        </Button>
      </div>
    </footer>
  );
};

export default connect(null, { toggleContact })(footer);
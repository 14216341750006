import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { toggleModal } from "../state/actions/components";
import Loading from "./Loading";

// interface Props {
//   confirmAction: any;
//   content: any
//   isLoading: boolean;
//   labelOne: any;
//   labelTwo: any;
//   modal: boolean;
//   onlyClose: boolean;
//   showButtons: boolean;
//   title: string;
//   toggleModal: (args: any) => void;
// }

class Modal extends React.Component<any, any> {
  render() {
    const {
      isOpen,
      confirmAction,
      content,
      defaultState,
      isLoading,
      labelOne,
      labelTwo,
      onlyClose,
      showButtons,
      title,
      toggleModal
    } = this.props.modal;

    return (
      <Dialog open={isOpen} onClose={() => toggleModal({ defaultState: true })}>
        {isLoading ? (
          <DialogContent>
            <Loading title={title} />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
          </div>
        )}
        {showButtons ? (
          <DialogActions>
            <Button
              onClick={() => toggleModal({ defaultState: true })}
              color="primary"
            >
              {labelTwo}
            </Button>
            {onlyClose ? (
              <Button onClick={() => confirmAction()} color="primary">
                {labelOne}
              </Button>
            ) : null}
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => ({
  modal: state.components.modalState
});

export default connect(
  mapStateToProps,
  { toggleModal }
)(Modal);

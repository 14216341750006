import React from "react";
import Typography from "@material-ui/core/Typography";
import Loading from "../components/Loading";
import Chip from "@material-ui/core/Chip";

const ComboStats = (props: any) => {
  const { comboList, calculatingProbs, probError, probMsg } = props;
  const stats = comboList.total;
  const statsExists = stats !== undefined;

  if (!calculatingProbs && statsExists) {
    const min = stats.min.toFixed(2);
    const max = stats.max.toFixed(2);
    const act = stats.actual.toFixed(2);

    return (
      <div className="combo-stats">
        <Typography variant="h4" className="combo-stats__title">
          Probability Totals
        </Typography>
        <div className="combo-stats__container">
          <Chip label={`Minimum: ${min}`} />
          <Chip label={`Maximum: ${max}`} />
          <Chip label={`Actual: ${act}`} />
        </div>
      </div>
    );
  }

  if (calculatingProbs) {
    return <Loading title={probMsg} />;
  }

  if (probError) {
    return <Typography variant="h5">{probMsg}</Typography>;
  }

  return null;
};

export default ComboStats;

import React from "react";
import Button from "@material-ui/core/Button";
import { styles } from "../styles/inline";
import Slider from "@material-ui/lab/Slider";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import {
  adjustDeck,
  updateTickBox,
  runOptimizer,
  downloadDeck,
  toggleAttack,
  toggleDefense,
  toggleSpeed
} from "../state/actions/cards";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

class Optimizer extends React.Component<any, any> {
  state = {
    deckName: "",
    error: false,
    errorMsg: "Invalid Deck Name",
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  updateDeckName = event => {
    const deckName = event.target.value;
    this.setState({ deckName });
  };

  saveDeck = () => {
    const deckName = this.state.deckName;
    const nameIsValid = deckName.length > 0;
    const cards = this.props.cardList;
    const results = this.props.results;

    if (!nameIsValid) {
      this.setState({ error: true });
    }

    if (nameIsValid) {
      this.setState({ error: false, open: false }, () => {
        downloadDeck({ cards, results, deckName });
      });
    }
  };

  convertLevel(value: number) {
    if (value === 1) {
      return "Low";
    } else if (value === 2) {
      return "Medium";
    } else {
      return "High";
    }
  }

  render() {
    const copy = this.props.copy;
    return (
      <div className="optimizer-panel field-group">
        <Typography variant="h5">Requirements</Typography>
        <FormControl className="optimizer-panel__group">
          <Typography className="card-search__label">
            Deck Size: {this.props.deckSize}
          </Typography>
          <Slider
            value={this.props.deckSize}
            onChange={this.props.adjustDeck}
            min={40}
            max={60}
            step={1}
            id="deckSize"
          />
        </FormControl>
        <FormControl className="optimizer-panel__group">
          <FormControlLabel
            control={
              <Switch
                checked={this.props.handFirst}
                onChange={event => this.props.updateTickBox(event, "handFirst")}
                value="handFirst"
              />
            }
            label="Going First?"
          />
        </FormControl>
        <FormControl className="optimizer-panel__group">
          <Typography className="card-search__label">
            Attack: {this.convertLevel(this.props.attack)}
          </Typography>
          <Slider
            value={this.props.attack}
            onChange={this.props.toggleAttack}
            min={1}
            max={3}
            step={1}
            id="attack"
          />
        </FormControl>
        <FormControl className="optimizer-panel__group">
          <Typography className="card-search__label">
            Defense: {this.convertLevel(this.props.defense)}
          </Typography>
          <Slider
            value={this.props.defense}
            onChange={this.props.toggleDefense}
            min={1}
            max={3}
            step={1}
            id="defense"
          />
        </FormControl>
        <FormControl className="optimizer-panel__group">
          <Typography className="card-search__label">
            Speed: {this.convertLevel(this.props.speed)}
          </Typography>
          <Slider
            value={this.props.speed}
            onChange={this.props.toggleSpeed}
            min={1}
            max={3}
            step={1}
            id="speed"
          />
        </FormControl>
        <Button
          style={styles}
          className="input-button optimizer-panel__button"
          variant="contained"
          color="default"
          type="raised"
          onClick={() =>
            this.props.runOptimizer({
              cardList: this.props.cardList,
              deck: this.props.deckSize,
              hand: this.props.handFirst,
              attack: this.props.attack,
              defense: this.props.defense,
              speed: this.props.speed
            })
          }
        >
          Run Optimizer
        </Button>
        {this.props.results.length > 0 ? (
          <Button
            style={styles}
            className="input-button optimizer-panel__button"
            variant="contained"
            color="default"
            type="raised"
            onClick={() => this.handleOpen()}
          >
            Download Deck
          </Button>
        ) : null}
        <div className="optimizer__copy">
          {copy}
        </div>
        <Dialog open={this.state.open} onClose={() => this.handleClose()}>
          <DialogTitle id="optimizer-download">Save Deck As ...</DialogTitle>
          <DialogContent>
            {this.state.error ? (
              <Typography>{this.state.errorMsg}</Typography>
            ) : null}
            <FormControl>
              <TextField
                id="deckName"
                label="Deck Name"
                defaultValue={this.state.deckName}
                onChange={event => this.updateDeckName(event)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveDeck} color="primary">
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  deckSize: state.cards.deckSize,
  handFirst: state.cards.handFirst,
  cardList: state.cards.cardList,
  results: state.cards.optimalResults,
  attack: state.cards.attack,
  defense: state.cards.defense,
  speed: state.cards.speed
});

export default connect(
  mapStateToProps,
  {
    adjustDeck,
    updateTickBox,
    runOptimizer,
    toggleAttack,
    toggleDefense,
    toggleSpeed
  }
)(Optimizer);

// interface Optimizer {
//   deckSize: number
//   attack: number
//   defense: number
//   speed: number
//   results: any[]
//   handFirst: boolean
//   adjustDeck: any
//   updateTickBox: any
//   cardList: any[]
//   toggleAttack: any
//   toggleDefense: any
//   toggleSpeed: any
//   runOptimizer: any
// }

import {
  TOGGLE_SIDEBAR,
  TOGGLE_MODAL,
  TOGGLE_TOPDRAWER,
  TOGGLE_CONTACT,
  SET_ASSETS
} from '../actiontypes/components';

export const setAssets = (payload: any[]) => (dispatch: any) => {
  dispatch({
    type: SET_ASSETS,
    payload
  });
}

export const toggleSidebar = (bool: boolean) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    bool
  });
};

export const toggleModal = (args: any) => (dispatch: any) => {
  const {
    defaultState,
  } = args;

  if (defaultState) {
    dispatch({
      type: TOGGLE_MODAL,
      payload: {
        isOpen: false,
        confirmAction: false,
        content: null,
        defaultState: true,
        isLoading: false,
        labelOne: null,
        labelTwo: null,
        onlyClose: false,
        showButtons: false,
        toggleModal: null,
        title: ""
      }
    });
  }

  if (!defaultState) {
    dispatch({
      type: TOGGLE_MODAL,
      payload: args
    })
  }
};

export const toggleTopDrawer = (bool: boolean) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_TOPDRAWER,
    bool
  });
};

export const toggleContact = (bool: boolean) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_CONTACT,
    bool
  });
}
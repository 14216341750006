import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { getCardTypes } from "../functions/general";

const ToolTipText = (props: any) => {
  const { card, formatPendulumText } = props;
  const cardCategory = card.category1;
  const isMonster = cardCategory === "Monster";
  const isSpell = cardCategory === "Spell";
  const isTrap = cardCategory === "Trap";
  const cardTypes = getCardTypes(card);
  const types = cardTypes.map((type, index) => {
    if (index !== cardTypes.length - 1) {
      return ` ${type} | `;
    }
    return ` ${type}`;
  });
  const attribute = card.attribute;
  const type = card.type;
  const isXyz = card.isXyz;
  const isLink = card.isLink;
  const isPendulum = card.isPendulum;
  const attack = card.atk >= 0 ? card.atk : '?';
  const defense = card.def >= 0 ? card.def : "?";

  if (isMonster) {
    return (
      <React.Fragment>
        <Paper className="tooltip__body">
          <Typography variant="h6">{card.name}</Typography>
          <Typography variant="body2">Attribute: {attribute}</Typography>
          <Typography variant="body2">Type: {type}</Typography>
          <Typography variant="body2">Card Type: {types}</Typography>
          {isLink || isXyz ? null : (
            <Typography variant="body2">Level: {card.level}</Typography>
          )}
          {isLink ? (
            <Typography variant="body2">Rating: {card.level}</Typography>
          ) : null}
          {isXyz ? (
            <Typography variant="body2">Rank: {card.rank}</Typography>
          ) : null}
          {isPendulum ? (
            <Typography variant="body2">Scale: {card.scale}</Typography>
          ) : null}
          {!card.pendulum && !card.isPendulum ? (
            <Typography>{card.text}</Typography>
          ) : (
            formatPendulumText(card)
          )}
          <Typography variant="body2">ATK: {attack}</Typography>
          <Typography variant="body2">DEF: {defense}</Typography>
        </Paper>
      </React.Fragment>
    );
  }

  if (isSpell) {
    return (
      <React.Fragment>
        <Paper className="tooltip__body">
          <Typography variant="h6">{card.name}</Typography>
          <Typography variant="body2">Type: {types}</Typography>
          <Typography>{card.text}</Typography>
        </Paper>
      </React.Fragment>
    );
  }

  if (isTrap) {
    return (
      <React.Fragment>
        <Paper className="tooltip__body">
          <Typography variant="h6">{card.name}</Typography>
          <Typography variant="body2">Type: {types}</Typography>
          <Typography>{card.text}</Typography>
        </Paper>
      </React.Fragment>
    );
  }

  return null;
};

export default ToolTipText;

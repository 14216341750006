import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const ConditionalSelect = (props: Props) => {
  const {
    condition,
    selectedCategory,
    subCategory,
    updateValue,
    value
  } = props;

  return (
    <FormControl className="card-search__group">
      <InputLabel htmlFor="category">{condition}</InputLabel>
      <Select
        id="category"
        onChange={event => updateValue(event, selectedCategory)}
        value={value}
      >
        {subCategory.map((cat: string, index: number) => (<MenuItem value={cat} key={index}>{cat}</MenuItem>))}
      </Select>
    </FormControl>
  )
}

export default ConditionalSelect;

interface Props {
  condition: any
  selectedCategory: string
  subCategory: any
  updateValue: any
  value: any
}
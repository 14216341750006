import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import firebase from "firebase/app";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { Email } from "../interfaces/contact";
import { toggleContact } from "../state/actions/components";
import Loading from "./Loading";

class Contact extends React.Component<any, any> {
  state = {
    contactAddress: "",
    ticketTypes: ["Select", "General Information", "App Issues", "Feedback"],
    selectedType: "Select",
    severity: ["Select", "Low", "Medium", "High"],
    selectedSeverity: "Select",
    text: "",
    error: false,
    errorMsg: "An Error Has Occured. Try Again",
    isSending: false,
    confirmation: false
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  updateFieldValue = args => {
    const { event, field } = args;
    this.setState({ [field]: event.target.value });
  };

  submitForm = () => {
    const { contactAddress, selectedType, selectedSeverity, text } = this.state;
    let { error, errorMsg } = this.state;
    const emailIsValid =
      this.validateEmail(contactAddress) && contactAddress.length > 0;
    const selectedValuesAreValid =
      selectedSeverity !== "Select" && selectedType !== "Select";
    const textIsValid = text.length > 0;
    const sendEmail = firebase.functions().httpsCallable("sendTicket");

    if (!emailIsValid) {
      error = true;
      errorMsg = "Please provide a valid email address";
      this.setState({ error, errorMsg });
    }

    if (!textIsValid) {
      error = true;
      errorMsg = "Please provide a message to send.";
      this.setState({ error, errorMsg });
    }

    if (!selectedValuesAreValid) {
      error = true;
      errorMsg = "Please ensure all select fields are valid";
      this.setState({ error, errorMsg });
    }

    if (emailIsValid && selectedValuesAreValid && textIsValid) {
      this.setState(
        { isSending: true, error: false, confirmation: false },
        async () => {
          try {
            const _response = await sendEmail({
              contactAddress,
              ticketType: selectedType,
              severity: selectedSeverity,
              text
            });
            const response: Email = _response.data;
            this.setState({ confirmation: true, isSending: false });
          } catch (error) {
            this.setState({ isSending: false, error: true, errorMsg }, () => {
              console.log(error);
            });
          }
        }
      );
    }
  };

  closeForm = () => {
    const contactAddress = "";
    const selectedType = "Select";
    const selectedSeverity = "Select";
    const text = "";
    const error = false;
    const isSending = false;
    const confirmation = false;
    this.setState({
      contactAddress,
      selectedType,
      selectedSeverity,
      text,
      error,
      isSending,
      confirmation
    });
    this.props.toggleContact(false);
  };

  componentDidMount() {
    const user = this.props.user;
    const hasUser = user !== null;
    if (hasUser) {
      this.setState({ contactAddress: user.email });
    }
  }

  render() {
    const { contact, user } = this.props;
    const {
      contactAddress,
      ticketTypes,
      selectedType,
      severity,
      selectedSeverity,
      text,
      error,
      errorMsg,
      isSending,
      confirmation
    } = this.state;
    const hasType = selectedType !== "Select";
    const hasSeverity = selectedType !== "Select";
    const hasContact = contactAddress !== "" || user !== null;
    const userExists = user !== null;
    const hasAll = hasType && hasSeverity && hasContact;
    return (
      <div>
        <Dialog open={contact} className="contact">
          <div>
            <DialogTitle>Contact</DialogTitle>
            {error ? <Typography className="contact__error">{errorMsg}</Typography> : null}
          </div>
          <DialogContent className="contact__form">
            {isSending ? (
              <Loading title="Submitting ..." />
            ) : (
              <div className="contact__form-outer">
                {confirmation ? (
                  <div>
                    <Typography>Thank You For Your Submission</Typography>
                  </div>
                ) : (
                  <div className="contact__form-inner">
                    <FormControl className="contact__fieldgroup">
                      <InputLabel htmlFor="reason">Query Type</InputLabel>
                      <Select
                        id="reason"
                        value={selectedType}
                        onChange={event =>
                          this.updateFieldValue({
                            event,
                            field: "selectedType"
                          })
                        }
                      >
                        {ticketTypes.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className="contact__fieldgroup">
                      <InputLabel htmlFor="severity">Priority Level</InputLabel>
                      <Select
                        id="severity"
                        value={selectedSeverity}
                        onChange={event =>
                          this.updateFieldValue({
                            event,
                            field: "selectedSeverity"
                          })
                        }
                      >
                        {severity.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!userExists ? (
                      <FormControl className="contact__fieldgroup">
                        <TextField
                          label="Email Address"
                          defaultValue={contactAddress}
                          onChange={event =>
                            this.updateFieldValue({
                              event,
                              field: "contactAddress"
                            })
                          }
                        />
                      </FormControl>
                    ) : null}
                    {hasAll ? (
                      <div>
                        <TextField
                          label="Message"
                          multiline
                          rowsMax="50"
                          value={text}
                          onChange={event =>
                            this.updateFieldValue({ event, field: "text" })
                          }
                          variant="standard"
                          className="contact__message"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {!isSending ? (
              <Button color="secondary" onClick={() => this.closeForm()}>
                {!confirmation ? "Cancel" : "Close"}
              </Button>
            ) : null}
            {!confirmation && !isSending ? (
              <Button color="primary" onClick={() => this.submitForm()}>
                Submit
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  contact: state.components.contact,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  { toggleContact }
)(Contact);

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import SidebarComponent from "../appComponents/SidebarComponent";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { toggleSidebar, toggleContact } from "../state/actions/components";
import firebase from "firebase/app";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

class Appbar extends React.Component<Props, any> {
  state = {
    isActive: false,
    isAuth: false,
    isApp: false,
    navOpen: null
  };

  signOut = () => {
    this.setState({ navOpen: null }, () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem(process.env.TOKEN_ID);
          navigate("/signin");
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ navOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ navOpen: null });
  };

  navigateTo = (uri: string) => {
    navigate(uri);
  };

  menuNavTo = (uri: string) => {
    this.setState({ navOpen: null }, () => {
      navigate(uri);
    });
  };

  signIn() {
    return () => {
      navigate("/signin");
    };
  }

  goHome() {
    return () => {
      navigate("/");
    };
  }

  componentDidMount() {
    if (typeof window !== undefined) {
      const isApp = window.location.pathname.includes("app");
      firebase.auth().onAuthStateChanged(async _user => {
        const token = localStorage.getItem(process.env.TOKEN_ID);
        const productExp = new Date(parseInt(token) * 1000);
        const now = new Date();
        const isActive = now < productExp;

        if (isActive && _user) {
          this.setState({ isActive });
        }
      });
      this.setState({ isApp });
      
    }
  }

  render() {
    const isLoggedIn = this.props.user !== null;
    const isApp = this.state.isApp;
    const { navOpen, isActive } = this.state;
    return (
      <div>
        <AppBar position="static" className="appbar">
          <Toolbar className="appbar__toolbar">
            <div className="appbar__menu">
              {isApp ? (
                <IconButton
                  className="appbar__button"
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => this.props.toggleSidebar(!this.props.sidebar)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <Typography
                  variant="h4"
                  color="inherit"
                  className="appbar__btn"
                  onClick={this.goHome()}
                >
                  DeckDB
                </Typography>
              )}
            </div>
            {isApp ? (
              <Typography
                variant="h4"
                color="inherit"
                className="appbar__btn"
                onClick={this.goHome()}
              >
                DeckDB
              </Typography>
            ) : null}
            <div>
              {(isLoggedIn || isActive) ? (
                <div>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={this.handleClick}
                  >
                    Menu
                  </Button>
                  <Menu
                    id="nav-menu"
                    anchorEl={navOpen}
                    open={Boolean(navOpen)}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={() => this.menuNavTo("/app")}>
                      App
                    </MenuItem>
                    <MenuItem onClick={() => this.menuNavTo("/docs")}>
                      Docs
                    </MenuItem>
                    <MenuItem onClick={() => this.menuNavTo("/account")}>
                      Account
                    </MenuItem>
                    <MenuItem onClick={() => this.menuNavTo("/blog")}>
                      Blog
                    </MenuItem>
                    <MenuItem onClick={() => this.props.toggleContact(true)}>
                      Contact
                    </MenuItem>
                    <MenuItem onClick={() => this.signOut()}>Sign Out</MenuItem>
                  </Menu>
                </div>
              ) : (
                <div>
                  <Button
                    variant="flat"
                    color="inherit"
                    onClick={() => this.menuNavTo("/blog")}
                  >
                    Blog
                  </Button>
                  <Button
                    variant="flat"
                    color="inherit"
                    onClick={() => this.menuNavTo("/docs")}
                  >
                    Docs
                  </Button>

                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={this.signIn()}
                  >
                    Sign In
                  </Button>
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <SidebarComponent copies={this.props.copies} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  sidebar: state.components.sidebar
});

export default connect(
  mapStateToProps,
  { toggleSidebar, toggleContact }
)(Appbar);

interface Props {
  user: any;
  toggleSidebar: any;
  sidebar: boolean;
  toggleContact: (bool: boolean) => void;
  copies: any;
}

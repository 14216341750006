import React from "react";
import firebase from "firebase/app";
import Button from "@material-ui/core/Button";
import { styles } from "../styles/inline";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { loadDecks, loadConfig, clearAll } from "../state/actions/cards";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loading from "../components/Loading";
import ConfirmModal from "./ConfirmModal";
import MaterialTable from "material-table";
import "firebase/functions";

class SaveLoad extends React.Component<any, any> {
  state = {
    name: "",
    confirmation: "",
    content: null,
    error: false,
    errMsg: "",
    open: false,
    saveOpen: false,
    loadOpen: false,
    loading: false,
    componentIsLoading: true,
    deleteCheckOpen: false,
    updateCheckOpen: false,
    deleting: false,
    updating: false,
    deckToUpdate: null,
    deckToDelete: null,
    configs: [],
    uid: null,
  };

  updateName = (event: any) => {
    this.setState({
      name: event.target.value
    });
  };

  confirmUpdate = () => {
    const { deckId, name } = this.state.deckToUpdate;
    const userHasConfirmed = firebase
      .functions()
      .httpsCallable(process.env.SAVE_CONFIG);
    const uid = this.state.uid;
    const cardList = this.props.cardList;
    const combos = this.props.combos;
    const optimalResults = this.props.optimalResults;
    const dateCreated = new Date().getTime();
    this.setState({ updating: true }, () => {
      userHasConfirmed({
        uid,
        deckId,
        name,
        combos,
        optimalResults,
        dateCreated,
        cardList
      }).then(res => {
        const data = res.data;
        const { errDetails, error, message } = data;

        if (error) {
          this.setState(
            { error, errMsg: message, componentIsLoading: false },
            () => console.log(errDetails)
          );
        }

        this.setState(
          {
            name: "",
            confirmation: message,
            updateCheckOpen: false,
            updating: false,
            deckToUpdate: null
          },
          () => {
            this.loadConfigs();
            this.handleClose("update");
          }
        );
      });
    });
  };

  saveConfig = () => {
    if (this.state.name.length > 0) {
      this.setState({ error: false, errMsg: "", loading: true }, () => {
        const saveConfig = firebase
          .functions()
          .httpsCallable(process.env.SAVE_CONFIG);
        const uid = this.state.uid;
        const now = new Date().getTime();
        const name = this.state.name;
        const uniqId = `${uid}${now}_${name}`;

        saveConfig({
          uid,
          deckId: uniqId,
          name: this.state.name,
          cardList: this.props.cardList,
          combos: this.props.combos,
          optimalResults: this.props.optimalResults,
          dateCreated: new Date().getTime()
        }).then(res => {
          const data = res.data;
          const { errDetails, error, message } = data;

          if (error) {
            this.setState({ error, errMsg: message }, () =>
              console.log(errDetails)
            );
          }

          this.setState(
            {
              name: "",
              confirmation: message,
              saveOpen: false,
              loading: false
            },
            () => {
              this.loadConfigs();
            }
          );
        });
      });
    }
  };

  configOptions = (config: any) => {
    const content = (
      <div>
        <h3 style={styles.header}>Load or Delete</h3>
        <div style={styles.flexRow}>
          <Button
            onClick={() => this.props.loadConfig(config)}
            style={styles}
            className="input-button"
            variant="contained"
            color="default"
            type="raised"
          >
            Load Config
          </Button>
          <Button
            onClick={() => this.saveConfig()}
            style={styles}
            className="input-button"
            variant="contained"
            color="default"
            type="raised"
          >
            Delete Config
          </Button>
        </div>
      </div>
    );

    this.setState(
      {
        content
      },
      this.props.toggleModal(true)
    );
  };

  handleOpen = (type: string) => {
    const isSaveConfig = type === "save";
    const isLoadConfig = type === "load";
    const isDeleteCheck = type === "delete";
    const isUpdateCheck = type === "update";

    if (isSaveConfig) {
      const saveOpen = true;
      this.setState({ saveOpen });
    }

    if (isLoadConfig) {
      const loadOpen = true;
      this.setState({ loadOpen });
    }

    if (isDeleteCheck) {
      const deleteCheckOpen = true;
      this.setState({ deleteCheckOpen });
    }

    if (isUpdateCheck) {
      const updateCheckOpen = true;
      this.setState({ updateCheckOpen });
    }
  };

  handleClose = (type: string) => {
    const isSaveConfig = type === "save";
    const isLoadConfig = type === "load";
    const isDeleteCheck = type === "delete";
    const isUpdateCheck = type === "update";

    if (isSaveConfig) {
      const saveOpen = false;
      this.setState({ saveOpen });
    }

    if (isLoadConfig) {
      const loadOpen = false;
      this.setState({ loadOpen });
    }

    if (isDeleteCheck) {
      const deleteCheckOpen = false;
      this.setState({ deleteCheckOpen });
    }

    if (isUpdateCheck) {
      const updateCheckOpen = false;
      this.setState({ updateCheckOpen });
    }
  };

  loadConfigs = () => {
    this.setState({ componentIsLoading: true }, async () => {
      const loadConfig = firebase
        .functions()
        .httpsCallable(process.env.LOAD_CONFIG);
      firebase.auth().onAuthStateChanged(async _user => {
        const uid = _user.uid;
        const _data = await loadConfig({ uid });
        const { error, errDetails, message, payload } = _data.data;
        const hasError = error !== false;
        const save = (deckId: string, name: string) => (
          <Button
            variant="contained"
            color="default"
            onClick={() => this.stageConfigForUpdate({ deckId, name })}
          >
            Overwrite
          </Button>
        );

        const del = (deckId: string) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.stageConfigForDelete(deckId)}
          >
            Delete
          </Button>
        );

        const load = params => {
          const { cardList, combos, optimalResults } = params;
          return (
            <Button
              variant="contained"
              color="default"
              onClick={() =>
                this.props.loadConfig({ cardList, combos, optimalResults })
              }
            >
              Load
            </Button>
          );
        };

        if (hasError) {
          this.setState(
            { error, errMsg: message, componentIsLoading: false },
            () => console.log(errDetails)
          );
        }

        if (!hasError) {
          const configs = payload.map(config => {
            return {
              name: config.name,
              delete: del(config.deckId),
              save: save(config.deckId, config.name),
              load: load(config)
            };
          });
          this.setState({ componentIsLoading: false, configs, uid });
        }
      });
    });
  };

  stageConfigForDelete = (deckId: string) => {
    this.setState({ deckToDelete: deckId }, () => {
      this.handleOpen("delete");
    });
  };

  stageConfigForUpdate = (params: any) => {
    const { deckId, name } = params;
    this.setState({ deckToUpdate: { deckId, name } }, () => {
      this.handleOpen("update");
    });
  };

  confirmDelete = () => {
    const deckId = this.state.deckToDelete;
    const userHasConfirmed = firebase
      .functions()
      .httpsCallable(process.env.REMOVE_CONFIG);
    this.setState({ deleting: true }, () => {
      userHasConfirmed({ deckId }).then(() => {
        this.setState({ deleting: false, deckToDelete: null }, () => {
          this.loadConfigs();
          this.handleClose("delete");
        });
      });
    });
  };

  componentDidMount() {
    this.loadConfigs();
  }

  render() {
    const copy = this.props.copy;
    const isLoading = this.state.componentIsLoading;
    const saveConfig = (
      <Dialog
        open={this.state.saveOpen}
        onClose={() => this.handleClose("save")}
      >
        {this.state.loading ? (
          <DialogContent>
            <Typography>Saving ...</Typography>
            <CircularProgress className="loading__wheel" color="secondary" />
          </DialogContent>
        ) : (
          <div>
            <DialogContent>
              <DialogTitle>Save Configuration</DialogTitle>
              <Typography>
                {this.state.errMsg === "" ? "" : this.state.errMsg}
              </Typography>
              <div>
                <FormControl style={styles}>
                  <TextField
                    id="deckName"
                    label="Deck Name"
                    defaultValue={this.state.name}
                    onChange={event => this.updateName(event)}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose("save")} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.saveConfig()} color="primary">
                Save
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
    if (isLoading) {
      return <Loading title="Loading ..." />;
    } else {
      return (
        <div className="save-load">
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleOpen("save")}
            className="save-load__button"
          >
            New Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.props.clearAll()}
            className="save-load__button"
          >
            Start New Build
          </Button>
          <div className="save-load__table">
            <MaterialTable
              columns={[
                { title: "Name", field: "name" },
                { title: "Update", field: "save" },
                { title: "Load", field: "load" },
                { title: "Delete", field: "delete" }
              ]}
              data={this.state.configs}
              options={{
                exportButton: false,
                search: false,
                toolbar: false,
                header: false
              }}
              title="Saved Configurations"
            />
          </div>

          {saveConfig}
          <ConfirmModal
            handleClose={this.handleClose}
            loading={this.state.deleting}
            confirmAction={this.confirmDelete}
            isOpen={this.state.deleteCheckOpen}
            loadingTitle="Deleting..."
            title={"Delete Config?"}
            type={"delete"}
          />
          <ConfirmModal
            handleClose={this.handleClose}
            loading={this.state.updating}
            confirmAction={this.confirmUpdate}
            isOpen={this.state.updateCheckOpen}
            loadingTitle="Saving..."
            title={"Overwrite Save?"}
            type={"update"}
          />
          <div className="save-load__copy">{copy}</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  cardList: state.cards.cardList,
  combos: state.cards.combos,
  optimalResults: state.cards.optimalResults,
  user: state.auth.user,
  decks: state.cards.decks
});

export default connect(
  mapStateToProps,
  { loadDecks, loadConfig, clearAll }
)(SaveLoad);

// interface SaveLoad {
//   cardList: any[]
//   combos: any[]
//   user: any
//   decks: any[]
//   toggleModal: any
//   loadConfig: any
//   optimalResults: any
//   loadDecks: any
// }

// interface State {
//   name: string
//   confirmation: string
//   content: any
// }

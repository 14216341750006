import React from 'react'
import Slider from '@material-ui/lab/Slider'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const ConditionalSlider = (props: Props) => {
  const {
    condition,
    levelDisabled,
    max,
    min,
    scaleDisabled,
    selectedCategory,
    selectedLevel,
    selectedScale,
    updateLevel,
    updateScale,
    updateTickBox
  } = props;

  if (selectedCategory.includes('Level')) {
    return (
      <div>
        <FormControl className="card-search__slider">
          <FormControlLabel
            control={
              <Switch
                checked={levelDisabled}
                onChange={event => updateTickBox(event, 'levelDisabled')}
                value="levelDisabled"
              />
            }
            label="Disable Level"
          />
        </FormControl>
        <FormControl className="card-search__slider">
          <Typography className="card-search__label">{condition} {selectedLevel}</Typography>
          <Slider
            value={selectedLevel}
            onChange={updateLevel}
            min={min}
            max={max}
            step={1}
            id="selectedLevel"
            disabled={levelDisabled}
          />
        </FormControl>
      </div>
    )
  }

  return (
    <div>
      <FormControl className="card-search__tickbox">
        <FormControlLabel
          control={
            <Switch
              checked={scaleDisabled}
              onChange={event => updateTickBox(event, 'scaleDisabled')}
              value="scaleDisabled"
            />
          }
          label="Disable Scale"
        />
      </FormControl>
      <FormControl className="card-search__slider">
        <Typography className="card-search__label">{condition} {selectedScale}</Typography>
        <Slider
          value={selectedScale}
          onChange={updateScale}
          min={min}
          max={max}
          step={1}
          id="selectedScale"
          disabled={scaleDisabled}
        />
      </FormControl>
    </div>
  )
}

export default ConditionalSlider;

interface Props {
  condition: any
  selectedCategory: string
  min: number
  max: number
  selectedLevel: any
  selectedScale: any
  levelDisabled: boolean
  scaleDisabled: boolean
  updateLevel: any
  updateScale: any
  updateTickBox: any
}
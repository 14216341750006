export const toTitleCase = (input: string) => {
  const words = input.split(/(\s|-)+/),
    output = [];

  for (let i = 0, len = words.length; i < len; i += 1) {
    output.push(words[i][0].toUpperCase() + words[i].toLowerCase().substr(1));
  }

  return output.join("");
};

export const classifyCardCategories = (cards: any) => {
  const monsters = cards.filter(
    card => card.category1 === "Monster" && card.extraDeck !== true
  );
  const spells = cards.filter(card => card.category1 === "Spell");
  const traps = cards.filter(card => card.category1 === "Trap");
  const extra = cards.filter(card => card.extraDeck === true);
  const hasCards = cards.length > 0;
  const hasMonsters = monsters.length > 0;
  const hasSpells = spells.length > 0;
  const hasTraps = traps.length > 0;
  const hasExtra = extra.length > 0;
  return {
    hasCards,
    hasMonsters,
    monsters,
    hasSpells,
    spells,
    hasTraps,
    traps,
    hasExtra,
    extra
  };
};

export const formatCombos = (params: any) => {
  const { _combos, cardList } = params;
  const combos = [];

  for (const key in _combos) {
    const keyIsValid = key !== "total";
    if (_combos.hasOwnProperty(key) && keyIsValid) {
      const element = _combos[key];
      const output = {
        name: null,
        min: null,
        max: null,
        actual: null,
        input: 0,
        output: 0,
        power: 0,
        disruption: 0,
        speed: 0
      };

      // Set Combo Properties
      cardList.forEach(item => {
        const card = item.card;
        const index = card.index;
        const hasIndex = index.length > 0;

        if (hasIndex) {
          index.forEach(piece => {
            const { comboName, comboId } = piece;
            const nameMatch = comboId === key;

            if (nameMatch) {
              output.name = comboName;
              output.min = element.total.min;
              output.max = element.total.max;
              output.actual = element.total.actual;
            }
          });
        }
      });

      cardList.forEach(item => {
        const card = item.card;
        const _combo = card.combo[key];
        const comboExists = _combo !== undefined;
        const canTutor =
          card.tutor === true ||
          card.text.toLowerCase().includes("from your deck to your");
        const canDraw = card.drawPower === true;

        if (canTutor) {
          output.speed += 10;
        } else if (canDraw) {
          output.speed += 8;
        }

        if (comboExists) {
          const isComboOutput = _combo.includes("group_6");

          if (isComboOutput) {
            const cat1 = card.category1.toLowerCase();
            const cat2 = card.category2.toLowerCase();
            const isDisruptive = card.disruptive === true;
            const isTrap = cat1 === "trap";
            const isCounterTrap = isTrap && cat2 === "counter";
            const isNormalTrap = isTrap && cat2 === "normal";
            const isContinuousTrap = isTrap && cat2 === "continuous";
            const isSpell = cat1 === "spell";
            const isQuickplay = isSpell && cat2 === "quick-play";
            const isUnbeatable = card.atk >= 4000;
            const isSuper = card.atk >= 3000 && card.atk < 4000;
            const isPowerful = card.atk >= 2500 && card.atk < 3000;
            const isStrong = card.atk >= 2000 && card.atk < 2500;
            const isOkay = card.atk >= 1700 && card.atk < 2000;

            if (isDisruptive) {
              output.disruption += 10;
            } else if (isCounterTrap) {
              output.disruption += 9;
            } else if (isQuickplay) {
              output.disruption += 8;
            } else if (isContinuousTrap) {
              output.disruption += 7;
            } else if (isNormalTrap) {
              output.disruption += 6;
            }

            if (isOkay) {
              output.power += 0.5;
            } else if (isStrong) {
              output.power += 1;
            } else if (isPowerful) {
              output.power += 7;
            } else if (isSuper) {
              output.power += 8;
            } else if (isUnbeatable) {
              output.power += 10;
            }

            output.output += 1;
          } else {
            output.input += 1;
          }
        }
      });
      combos.push(output);
    }
  }
  return combos;
};

export const formatRadarData = (combos: any) => {
  const addNum = (a, b) => a + b;
  const valueCheck = val => {
    if (val > 10) return 10;
    return val;
  };

  const getConsistencyScore = val => {
    const poor = val < 0.3;
    const fair = val >= 0.3 && val < 0.45;
    const good = val >= 0.45 && val < 0.66;
    const great = val >= 0.66;

    if (poor) return 6;
    if (fair) return 8;
    if (good) return 9;
    if (great) return 10;
  };

  const labels = ["Attack", "Consistency", "Disruption", "Output", "Speed"];
  let totalProb = 0;
  let totalDefense = 0;
  let totalOutput = 0;
  let totalPower = 0;
  let totalSpeed = 0;
  let datasets: any;
  let totalCombos = combos.length;

  totalProb =
    combos
      .map(combo => {
        const { actual } = combo;
        return getConsistencyScore(actual);
      })
      .reduce(addNum) / totalCombos;
  totalDefense =
    combos
      .map(combo => {
        const { disruption } = combo;
        return disruption;
      })
      .reduce(addNum) / totalCombos;
  totalOutput =
    combos
      .map(combo => {
        const { output } = combo;
        return output;
      })
      .reduce(addNum) / totalCombos;

  totalPower =
    combos
      .map(combo => {
        const { power } = combo;
        return power;
      })
      .reduce(addNum) / totalCombos;
  totalSpeed =
    combos
      .map(combo => {
        const { speed } = combo;
        return speed;
      })
      .reduce(addNum) / totalCombos;

  totalProb = valueCheck(totalProb);
  totalDefense = valueCheck(totalDefense);
  totalOutput = valueCheck(totalOutput);
  totalPower = valueCheck(totalPower);
  totalSpeed = valueCheck(totalSpeed);

  datasets = [
    {
      // label: 'Combo Stats',
      fill: true,
      backgroundColor: "rgba(93, 186, 196, 0.5)",
      pointBackgroundColor: "rgba(71, 144, 153, 1)",
      data: [totalPower, totalProb, totalDefense, totalOutput, totalSpeed]
    }
  ];

  return { labels, datasets };
};

export const getDisasterLevel = (rating: number) => {
  const isWolf = rating <= 6;
  const isTiger = rating > 6 && rating < 7;
  const isDemon = rating >= 7 && rating < 8;
  const isDragon = rating >= 8 && rating < 9;
  const isAbove = rating >= 9;

  if (isWolf) return "Wolf";
  if (isTiger) return "Tiger";
  if (isDemon) return "Demon";
  if (isDragon) return "Dragon";
  if (isAbove) return "Kami";
};

export const cardDataCheck = (card: any) => {
  const { text, category1, isRitual } = card;
  const txt = text.toLowerCase();
  const isSpell = category1.toLowerCase() === "spell";
  const specialSummon =
    txt.includes("special summon") && card.sumRest === false;
  const inherent1 = txt.includes(
    "if you control no monsters, you can special summon"
  );
  const inherent2 = txt.includes("if your opponent controls a monster and");
  const toHand = txt.includes("to your hand");
  const inherentSS = txt.includes("you can special summon this card");
  const deckSS = txt.includes("from your deck") && specialSummon;
  const GYSS = txt.includes("your gy") && specialSummon;
  const graveyardSS1 =
    txt.includes("from either player") &&
    txt.includes("grave") &&
    specialSummon;
  const graveyardSS2 = txt.includes("your grave") && specialSummon;
  const banishedSS1 =
    txt.includes("from either player") &&
    txt.includes("banished") &&
    specialSummon;
  const isRitualCard = isSpell && isRitual;
  const isFusionCard = txt.includes("fusion summon 1");
  const inherentFusion = isFusionCard && txt.includes("including this");
  const graveFusion1 = isFusionCard && txt.includes("gy");
  const graveFusion2 = isFusionCard && txt.includes("graveyard");
  const banishedSS2 = txt.includes("your banished") && specialSummon;
  const GYPickup = txt.includes("from your gy") && toHand;
  const GYPickup2 = txt.includes("in your gy") && toHand;
  const banishedPickup = txt.includes("banished") && toHand;
  const disruption = txt.includes("your opponent");
  const banishing1 = disruption && txt.includes("banish all");
  const banishing2 = disruption && txt.match(/banish ([0-9])+/);
  const destruction1 = disruption && txt.includes("destroy all");
  const destruction2 = disruption && txt.match(/destroy ([0-9])+/);
  const tutor1 = txt.includes("card from your deck to your hand");
  const tutor2 = txt.includes("monster from your deck to your hand");
  const tutor3 = txt.includes("spell card from your deck to your hand");
  const tutor4 = txt.includes("trap card from your deck to your hand");
  const tutor5 = txt.includes("from your deck to your extra deck");
  const tutor6 = txt.includes("from your deck to your g");
  const tutor7 = txt.includes("from your deck to the g");
  const tutor8 = txt.includes("from your deck") && txt.includes("to your hand");
  const isTutor =
    tutor1 ||
    tutor2 ||
    tutor3 ||
    tutor4 ||
    tutor5 ||
    tutor6 ||
    tutor7 ||
    tutor8;
  const isStarter =
    inherent1 || inherent2 || deckSS || isRitualCard || inherentFusion;
  const isRecovery =
    GYSS ||
    graveyardSS1 ||
    graveyardSS2 ||
    banishedSS1 ||
    banishedSS2 ||
    GYPickup ||
    banishedPickup ||
    graveFusion1 ||
    graveFusion2 ||
    GYPickup2;
  const isRemoval = banishing1 || banishing2 || destruction1 || destruction2;

  if (isStarter) {
    card.isStarter = true;
  }

  if (isRecovery) {
    card.isRecovery = true;
  }

  if (isRemoval) {
    card.isRemoval = true;
  }

  if (isTutor) {
    card.isTutor = true;
  }

  return card;
};

export const formatDoughnutData = (args: any) => {
  const addNum = (a, b) => a + b;
  const { cardList } = args;
  const mainDeck = cardList.filter(card => card.extraDeck === false);
  const tutors = mainDeck.filter(card => card.isTutor === true);
  const removal = mainDeck.filter(card => card.isRemoval === true);
  const recovery = mainDeck.filter(card => card.isRecovery === true);
  const disruption = mainDeck.filter(card => card.disruptive === true);
  let tutorCount = 0;
  let removalCount = 0;
  let recoveryCount = 0;
  let disruptionCount = 0;

  if (tutors.length > 0) {
    tutorCount = tutors.map(item => item.copies).reduce(addNum);
  }

  if (removal.length > 0) {
    removalCount = removal.map(item => item.copies).reduce(addNum);
  }

  if (recovery.length > 0) {
    recoveryCount = recovery.map(item => item.copies).reduce(addNum);
  }

  if (disruption.length > 0) {
    disruptionCount = disruption.map(item => item.copies).reduce(addNum);
  }

  const labels = ["Searchers", "Removal", "Recovery", "Disruption"];
  const datasets = [
    {
      data: [tutorCount, removalCount, recoveryCount, disruptionCount],
      backgroundColor: ["#5dbac4", "#c4785d", "#c4ac5d", "#965dc4"]
    }
  ];
  const options = {
    title: {
      display: false
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  return {
    data: {
      labels,
      datasets
    },
    options
  };
};

export const getCardTypes = (card: any) => {
  const {
    isContinuous,
    isCounter,
    isEffect,
    isEquip,
    isField,
    isFlip,
    isFusion,
    isGemini,
    isLink,
    isNormal,
    isPendulum,
    isQuick,
    isRitual,
    isSpirit,
    isSynchro,
    isToon,
    isTuner,
    isUnion,
    isXyz
  } = card;

  const output = [];

  if (isContinuous) output.push('Continuous');
  if (isCounter) output.push('Counter');
  if (isEffect) output.push('Effect');
  if (isEquip) output.push('Equip');
  if (isField) output.push('Field');
  if (isFlip) output.push('Flip');
  if (isFusion) output.push('Fusion');
  if (isGemini) output.push('Gemini');
  if (isLink) output.push('Link');
  if (isNormal) output.push('Normal');
  if (isPendulum) output.push('Pendulum');
  if (isQuick) output.push('Quick-Play');
  if (isRitual) output.push('Ritual');
  if (isSpirit) output.push('Spirit');
  if (isSynchro) output.push('Synchro');
  if (isToon) output.push("Toon");
  if (isTuner) output.push('Tuner');
  if (isUnion) output.push('Union');
  if (isXyz) output.push('Xyz');
  
  return output;
}

export const getCombo = (args: any) => {
  const {
    combo, comboList
  } = args;
  const { comboName, comboId } = combo;
  const output = { min: 0, max: 0, act: 0 };
  const comboExists = comboList.hasOwnProperty(comboId);

  if (!comboExists) {
    return output;
  }

  if (comboExists) {
    output.min = comboList[comboId].total.min;
    output.max = comboList[comboId].total.max;
    output.act = comboList[comboId].total.actual;
    return output;
  }
  return output;
}
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmModal = (props: any) => {
  const { handleClose, confirmAction, loading, isOpen, title, type, loadingTitle } = props;
  return (
    <Dialog open={isOpen} onClose={() => handleClose(type)} className="confirm-modal">
      <DialogContent>
        {loading ? (
          <div>
            <DialogTitle>{loadingTitle}</DialogTitle>
            <CircularProgress className="confirm-modal__progress" />
          </div>
        ) : (
            <DialogTitle>{title}</DialogTitle>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(type)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => confirmAction()} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;